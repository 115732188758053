import React from "react";
import Banner from "./banner"
import Search from "../../components/Search"
import Export from "./export";
import ProductList from "./productlist";
const Floriculture = (props) => {   
  return (
    <React.Fragment>
      <Banner/>
      <Search/>
      <Export/>
      <ProductList/>
    </React.Fragment>
  );
};

export default Floriculture;
