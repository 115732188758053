import React from "react";
const Enquire = (props) => {
  return (
    <React.Fragment>
      <section className="newsletter-area bg-img ptb-105">
  <div className="container">
    <div className="newsletter-wrapper">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-12 mt-6 ">
          <div className="fruits-newsletter-title">
            <span>Submit Your Requirements</span>
            <h3>Just One Step Ahead</h3>
          </div>
        </div>
        <div className="col-lg-8 col-md-8 col-12">
          <div id="mc_embed_signup" className="subscribe-form-furits f-right">
            <form className="kwes-form" id="subscribe-form" method="POST" action="https://kwes.io/api/foreign/forms/auNcdNbohnWnDLO83hJq">
              <div className="mc-form">
                <input
                  type="email"
                  name="EMAIL"
                  className="EMAIL"
                  placeholder="Enter Email Address"
                  autocomplete="off"
                />
                <div className="clear">
                  <input
                    type="submit"
                    value="Enquire Now"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button enquire-now"
                  />
                </div>
              </div>
            </form> 
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </React.Fragment>
  );
};

export default Enquire;
