import React from "react";
import Banner from "./banner"
import ProductList from "./productList"
import Export from "./export";
import Search from "../../components/Search"
const Beverage = (props) => {
    return (
        <React.Fragment>
            <Banner />
            <div id="product-list">
                <Search />
                <Export />
                <ProductList />
                <div id="not-found" class="mb-100"><h4>Not Found Such Any Product : (</h4></div>
            </div>
        </React.Fragment>
    );
};

export default Beverage;
