import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{'list-style-type': 'none !important'}}>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/turmeric-dosage-v2.png" alt="Turmeric"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Turmeric" href="#">Turmeric</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/ginger-on-wood-texture-royalty-free-image-801035860-1532725625.png" alt="Ginger"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Ginger" href="#">Ginger</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Spices/star-anise.png" alt="Star Anise"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Star Anise" href="#">Star Anise</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/170807181545-herbs-and-spices-cinnamon-exlarge-169.png" alt="Cinnamon"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Cinnamon" href="#">Cinnamon</a></h4>
              </div>
            </div>
          </li>
      
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Spices/100786.png" alt="Asafoetida"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Asafoetida" href="#">Asafoetida</a></h4>
              </div>
            </div>
          </li>
         
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/cumin-seeds-500x500.png" alt="Cumin"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Cumin" href="#">Cumin</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/shutterstock-268449350.png" alt="Black Pepper"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Black Pepper" href="#">Black Pepper</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/3812.png" alt="Garlic"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Garlic" href="#">Garlic</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/image1.png" alt="Clove"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Clove" href="#">Clove</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/image.png" alt="Mustard seeds"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Mustard seeds" href="#">Mustard seeds</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/edanaela.png" alt="Bay-leaf"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Bay-leaf" href="#">Bay-leaf</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/fresh-and-dry-red-chilli-pepper-in-bulk-supply-1337571.png" alt="Chili-pepper"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Chili-pepper" href="#">Chili-pepper</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/Bowl-cilantro-leaves-coriander-fruits-Coriandrum-sativum.png" alt="Coriander"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Coriander" href="#">Coriander</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/Saffron-spice-with-crocus.png" alt="Saffron"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Saffron" href="#">Saffron</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/Carom-Seeds.png" alt="Carom Seeds / Thyme"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Carom Seeds / Thyme" href="#">Carom Seeds / Thyme</a></h4>
              </div>
            </div>
          </li>
          
          
          
         
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/il_1140xN.1487179555_b4yu.png" alt="Green Cardamom"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Green Cardamom" href="#">Green Cardamom</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/U460979ee48fc4640a52e063ff56c71fbZ.png" alt="Peppercorns"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Peppercorns" href="#">Peppercorns</a></h4>
              </div>
            </div>
          </li>
        
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Spices/51oo+lo3UnL.png" alt="Brown Cardamom"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Brown Cardamom" href="#">Brown Cardamom</a></h4>
              </div>
            </div>
          </li>
        
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Spices/depositphotos_441209056-stock-photo-green-fresh-chili-peppers-background.png" alt="Green chili pepper"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Green chili pepper" href="#">Green chili pepper</a></h4>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
