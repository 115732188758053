import React from "react";
const Banner = (props) => {
   
  return (
    <React.Fragment>
<div className="breadcrumb-area-listing dehydrated-product mt-140">
  <div className="container">
    <div className="breadcrumb-content">
      <h2>Dehydrated Products</h2>
      <ul>
        <li><a title="Home" href="index.html">home</a></li>
        <li> Dehydrated Products </li>
      </ul>
    </div>
  </div>
</div>
    </React.Fragment>
  );
};

export default Banner;
