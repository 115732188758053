import React,{useEffect} from "react";
import Slider from "./slider";
import Import from "./import";
import Popular from "./popular";
import Choose from "./choose";
import ProductRange from "./productrange";
import Client from "./client";
import Indian from "./Indian"
import { appendScript } from "../Util/script";

const Dashboard = (props) => {
useEffect(()=>{
  appendScript("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js");
  appendScript("js/popper.js");
  appendScript("js/bootstrap.min.js");
  appendScript("js/lazysizes.min.js");
  // appendScript("js/owl.carousel.min.js");
  appendScript("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js");
  appendScript("js/plugins.js");
  appendScript("https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.7.0/js/lightgallery-all.min.js");
  appendScript("https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.1/jquery.validate.min.js");
  appendScript("https://cdnjs.cloudflare.com/ajax/libs/list.js/1.5.0/list.min.js");
  // appendScript("js/validator.js");
  appendScript("js/main.js");
  appendScript("js/search-product.js");
  appendScript("js/formspree-api-contact.js");
  appendScript("js/formspree-api-supplier.js");
  appendScript("https://kwesforms.com/js/v2/kwes2aa5.js");
    },[])
  return (
    <React.Fragment>
      <Slider />
      <a
        href="//profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3150623&PaArea=email"
        target="popup"
        onclick="window.open('//profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3150623&PaArea=email','popup','width=800,height=800'); return false;"
      >
        <img className="sticky" src="img/logo/drs_logo.png" alt="drs_logo" />
      </a>
      <span>
        <a
          href="../profiles.dunsregistered.com_443/DunsRegisteredProfileAnywhereErrore86e.html?key1=3150623&amp;PaArea=email"
          target="popup"
          onclick="window.open('../profiles.dunsregistered.com_443/DunsRegisteredProfileAnywhereErrore86e.html?key1=3150623&amp;PaArea=email','popup','width=1000,height=1000'); return false;"
        >
          {/* <img className="sticky" src="img/logo/drs_logo.png" alt="drs_logo"/> */}
          {/* <img className="sticky1" src="img/logo/click1.png" alt="drs_logo"/> */}
        </a>
      </span>
      <section className="pb-105 mrl-80">
        <Import />
      </section>
      <section className="product-style-area gray-bg-4 pb-105">
        <Popular />
      </section>
      <section className="fruits-choose-area">
        <Choose />
      </section>
      <section className="product-style-area pt-80 pb-30" id="product-range">
<ProductRange />
</section>
<section className="testimonials-area pt-40 pb-125 wishlist bg-img" style={{backgroundImage:' url(img/banner/testimonial2.jpg)'}} id="top-space">
    <Client/>
</section>
<section className="fruits-services ptb-100">
<Indian/>
</section>
    </React.Fragment>
  );
};

export default Dashboard;
