import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  indicator: {
    color: '#85bd02 !important'

  },
  default_tab:{
    color: '#666666',
    fontWeight: '500',
  }
}));

const Header = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
  }
 
  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [])
function handleChange(index){
 if(location.pathname === index){
   return classes.indicator;
 }else{
  return classes.default_tab;

 }

}
  return (
    <React.Fragment>
      <header>
        <div className="header-bottom wrapper-padding-2 res-header-sm sticker header-sticky-3 furits-header">
          <div className="container-fluid">
            <div className="header-bottom-wrapper">
              <div className="pisum-logo ptb-10">
                <a href="index.html" title="Pisum logo">
                  <img src="img/logo/output-onlinepngtools (6) (1) (3).png" alt="Pisum Logo" />
                </a>
              </div>
              <div id="google_translate_element"></div>
              <div className="menu-style-2 handicraft-menu menu-hover">
                <nav>
                  <ul className="ul" >


                    <li><Link to="/" title="Home" className={handleChange('/')} >Home</Link></li>


                    <li><Link to="/About" title="About Us" className={handleChange('/About')} >About Us</Link></li>


                    <li><a >Products</a>
                      <img src="img/icon-img/drop.png" alt="products" />
                      <ul className="single-dropdown">

                        <li><Link to="/grains"  className={handleChange('/grains')}>Grains and Cereal</Link></li>

                        <li><Link to="/pulses"  className={handleChange('/pulses')}>Pulses</Link></li>

                        <li><Link to="/spices"  className={handleChange('/spices')}>Spices</Link></li>

                        <li><Link to="/fruits"  className={handleChange('/fruits')}>Fruits</Link></li>

                        <li><Link to="/vegetbles" className={handleChange('/vegetbles')}>Vegetables</Link></li>

                        <li><Link to="/oilseeds" className={handleChange('/oilseeds')}>Oilseeds</Link></li>

                        <li><Link to="/floriculture" className={handleChange('/floriculture')}>Floriculture</Link></li>


                        {/* <li><Link to="/dryfruit">Dry Fruits</Link></li> */}

                        <li><Link to="/beverages" className={handleChange('/beverages')}>Beverages</Link></li>

                        <li><Link to="/dehydratedproduct" className={handleChange('/dehydratedproduct')}>Dehydrated Products</Link></li>

                      </ul>
                    </li>
                    <li><Link to="/certificate" title="Certificates" className={handleChange('/certificate')}>Certificates</Link></li>
                    <li style={{ display: 'none' }}></li>
                    <li style={{ display: 'none' }}></li>
                    <li><Link to="/blog" title="Blogs" className={handleChange('/blog')}>Blogs</Link></li>
                    <li style={{ display: 'none' }}></li>
                    <li><Link to="/contact-us" title="Contact Us" className={handleChange('/contact-us')}>Contact Us</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="mobile-menu-area handicraft-menu d-md-block col-md-12 col-lg-12 col-12 d-lg-none d-xl-none">
                <div className="mobile-menu">
                  <nav id="mobile-menu-active">
                    <ul className="menu-overflow" >

                      <li><Link to="index.html" title="Home">Home</Link></li>

                      <li><Link to="about-us/index.html" title="About Us">About Us</Link></li>

                      <li><Link to="#">Products</Link>
                        <ul>

                          <li><Link to="/certificate">Grains and Cereal</Link></li>

                          <li><Link to="/certificate">Pulses</Link></li>

                          <li><Link to="/certificate">Spices</Link></li>

                          <li><Link to="/certificate">Fruits</Link></li>

                          <li><Link to="/certificate">Vegetables</Link></li>

                          <li><Link to="/certificate">Oilseeds</Link></li>

                          <li><Link to="/floriculture">Floriculture</Link></li>

                          <li><a href="herbs/index.html">Herbs</a></li>

                          <li><Link to="/dryfruit">Dry Fruits</Link></li>

                          <li><Link to="/beverages">Beverages</Link></li>

                          <li><Link to="/dehydratedproduct">Dehydrated Products</Link></li>

                        </ul>
                      </li>
                      <li><Link to="/certificate" title="Certificates">Certificates</Link></li>
                      <li style={{ display: 'none' }}></li>



                      {/* <li><Link to="#" title="Become Supplier">Become Supplier</Link></li> */}



                      <li><Link to="/blog" title="Blogs">Blogs</Link></li>



                      <li><Link to="/careers" title="Careers">Careers</Link></li>



                      <li><Link to="/contact-us" title="Contact Us">Contact Us</Link></li>


                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
