import React from "react";
const About = (props) => {
  return (
    <React.Fragment>
        <section className="breadcrumb-area-certificates mt-140">
      <div className="container">
        <div className="breadcrumb-content" id="cert-bread">
          <h2>Certificates</h2>
          <ul>
            <li><a href="index.html" title="home">home</a></li>
            <li>Certificates</li>
          </ul>
        </div>
      </div>
    </section>
    </React.Fragment>
  );
};

export default About;
