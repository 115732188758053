import React from "react"
import { lazy , Suspense } from "react";
import Loader from "../../components/MainLayout/Loader"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Suspense fallback={<Loader/>}>

  
  <Route
    {...rest}
    render={props => {
      // if (isAuthProtected && !localStorage.getItem("authUser")) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/login", state: { from: props.location } }}
      //     />
      //   )
      // }
      return (
       

        <Layout >
        <Component {...props} /> 
         </Layout>
        
      )
    }}
    
  />
</Suspense>
    
 
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
