import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Grains & Cereal Exporters</h2>
    </div>
      <p class="text-dark text-justify"> India is one of the most important players in the global market today. Being one of the major agricultural exporters in the world, grain and cereal export from India has been growing rapidly. Pisum Foods is one of the most reliable grains importers from India. We work on the export of grains and cereal, fruits, vegetables, pulses, spices, and such other food products from India. Being one of the leading cereal exporters in India, we have been accredited to TPCI, APEDA, and such other certifications.</p>
      <p class="text-dark text-justify" id="space"> Known to be the best grains and cereal exporter in India, we at Pisum Foods make sure that premium quality grains and cereal in India are exported abroad. When we work on the export of Indian grains, we make sure that all the health and safety regulations are followed. With extensive experience in the Food export industry and export of Indian grains, we make sure that we can get the best buyers for grains exporter from India. If you are looking for cereal import from India, Pisum is the best choice for you!</p>
  </div>
</section>

    </React.Fragment>
  );
};

export default Search;
