import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{"list-style-type": 'none !important'}}>
                              
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/grains-and-cereal/Rye.png" alt="Rye"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Rye" href="#">Rye</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/corn.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/grains-and-cereal/Corn Maize.png" alt="Corn / Maize"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Corn / Maize" href="grains-and-cereal/corn.html">Corn / Maize</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/oats.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/pexels-nastyasensei-2290077.png" alt="Oats"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Oats" href="grains-and-cereal/oats.html">Oats</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/millet.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/bajra (1).png" alt="Millet / Bajra"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Millet / Bajra" href="grains-and-cereal/millet.html">Millet / Bajra</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/rice.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/white-rice.png" alt="Rice"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Rice" href="grains-and-cereal/rice.html">Rice</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/wheat.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/2436498+Wheat li jingwang istockphoto.png" alt="Wheat"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Wheat" href="grains-and-cereal/wheat.html">Wheat</a></h4>
              </div>
            </div>
          </li>
                                                                   
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/triticale.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/Triticale-rye-wheat.png" alt="Triticale"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Triticale" href="grains-and-cereal/triticale.html">Triticale</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/fonio.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/HTB13cEQs1SSBuNjy0Flq6zBpVXa7.png" alt="Fonio"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Fonio" href="grains-and-cereal/fonio.html">Fonio</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="grains-and-cereal/sorghum.html">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/grains-and-cereal/MDG--Ancient-cereals-quiz-010.png" alt="Sorghum / Jawari"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Sorghum / Jawari" href="grains-and-cereal/sorghum.html">Sorghum / Jawari</a></h4>
              </div>
            </div>
          </li>                          
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
