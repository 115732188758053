import React from "react";
import { Link } from "react-router-dom";

const ProductList = (props) => {
   
  return (
    <React.Fragment>
 <div className="wrapper mrl-80">
    <div className="sub-listing-area pt-40 pb-90">
      <div className="container">
        <ul className="list row" style={{'list-style-type': 'none !important'}}>
           
          <li className="col-xl-3 col-md-4">
            <div className="product-fruit-wrapper mb-30">
              <Link to="/dehydratedproduct-fruits">
                <div className="product-fruit-img">
                  <img className="lazyload" data-src="/img/dehydrated-fruits/dehydrated-apple-sliced.png" alt="dehydrated fruits"/>
                </div>
              </Link>
              <div className="product-fruit-content text-center">
                <h4><Link className="name" title="dehydrated fruits" to="/dehydratedproduct-fruits">dehydrated fruits</Link></h4>
              </div>
            </div>
          </li>                            
          <li className="col-xl-3 col-md-4">
            <div className="product-fruit-wrapper mb-30">
              <Link to="/dehydratedproduct-vegies">
                <div className="product-fruit-img">
                  <img className="lazyload" data-src="/img/dehydrated-products/dehydrated-spinach.png" alt="dehydrated vegetables"/>
                </div>
              </Link>
              <div className="product-fruit-content text-center">
                <h4><Link className="name" title="dehydrated vegetables" to="/dehydratedproduct-vegies">dehydrated vegetables</Link></h4>
              </div>
            </div>
          </li>                            
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default ProductList;
