import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           <section className="product-details dpt-170" style={{'background-color': '#e8f8df'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
        <div className="section-title-dehydrated text-center mb-80">
          <img className="lazyload" data-src="/img/icon-img/49.png" alt=""/>
          <h1>Global Market Demand of Dehydrated Vegetables</h1>
        </div>
      </div>
    </div>
          <div className="col-md-12 dehydrated-img">
            <p className="text-justify pt-30 pb-40">Dehydrated Vegetables are vegetables from which moisture content has been removed. The water content from these vegetables is removed by some special drying methods. Sun drying is one of the oldest methods used for dehydration. Dehydrated Vegetables are used in instant noodles, soups, snacks, fast food and such other products. The import of dehydrated vegetables from India has been increasing over the years due to the diverse uses and benefits of these veggies. These vegetables retain most of their nutritional content in the dehydrated state. Thus, the dehydrated vegetables in India are now exported all around the globe. </p>
          </div>
  </div>
    </section>
        </React.Fragment>
    );
};

export default Beverage;
