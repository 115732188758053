import React from "react";
const Export = (props) => {
   
  return (
    <React.Fragment>
<section className="pb-105 mrl-80">
  <div className="container">
    <div className="section-title-furits text-center mb-80">

      <h2 id="spice-head">Dehydrated Food Products Exporters</h2>
    </div>
      <p className="text-dark text-justify"> The dehydrated products market from India has been growing rapidly. Dehydration is basically the removal of water. Thus, dehydrated products are the products from which moisture content has been removed. The dehydrated products exporters from India export huge quantities of these products abroad each year. The export of dehydrated products includes the export of various products. Dehydrated vegetables are an essential component of the dehydrated products exported from India. Along with veggies, dehydrated fruits are also in great demand globally. Dehydrated dry fruits are widely used in desserts, cereal and such other products. The export of such products is a great opportunity for dehydrated food products exporters to earn good revenues.</p>
      <p className="text-dark text-justify" id="space"> </p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Export;
