import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
 <section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">
      <h2 id="spice-head">Indian Spices Exporter</h2>
    </div>
      <p class="text-dark text-justify"> Spices are one of the most important ingredients in Indian dishes. Indian Spices have been popular across the world for their unique flavor, aroma, and beautiful texture. Thus, the export of spices from India has increased substantially. We, at Pisum Foods, believe that Indian culture and our unique spices in India should reach each corner of the world. Pisum is one of the leading export companies for the export of Indian spices. We, being whole spice exporters, export numerous spices including turmeric, ginger, Star anise, Cinnamon, Asafoetida, Nutmeg, cumin, black pepper, and many more.</p>
      <p class="text-dark text-justify" id="space"> Pisum foods, one of the most recommended spices exporters in India, has a strong experience in the food industry, and we can get the best buyers for your products. Being one of the renowned exporters of Indian Masala we have connections across the globe. Through us, you can export Indian spices across any continent. We believe that the customer is our King and our process is perfectly transparent and reliable. The Head office located in the heart of Pune, we have trained executives who analyze the market so that we can give you the latest information available. If you are looking for spice exporters in India, we are here to serve you!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Search;
