import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
            <section className="product-details mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-details-content text-center mobile-center">
                                <h1>Indian Almond Nuts Exporters</h1>
                                <p className="mt-50 text-justify ">Almonds are popularly referred to as nuts and are teardrop-shaped edible seeds. Almonds are primarily fruits of the almond tree. The Almond Nuts exporter in India exports tonnes of nuts abroad every year. The popularity of Almond Nuts has been growing in the world. This may be a reason why Almond Nuts export from India has also been growing rapidly. This is a good time for the Indian Almond Nuts exporter to enter the international marketplace. The Almond Nuts export includes the export of various varieties of these nuts.</p>
                            </div>
                            <div className="goal-wrapper mb-30 mobile-center prl-30">
                                <h3></h3>
                                <p className="text-justify "></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Beverage;
