import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>

  <div class="container">
    <div class="section-title-furits text-center mb-80">
      <img class="lazyload" data-src="img/icon-img/leaf (1).png" alt="leaf-heading"/>
      <h2>Muyoni- Global Food Importers & Exporters</h2>
    </div>
      <p class="text-dark text-justify">“As the ‘land of spices’, India exports tons of various kinds of food. In fact, food export from India is a major source of revenue. To help your products reach to even the farthest corners of the world, Pisum Foods offers you various types of food export as well as spices export from India. Being among the leading food export companies in India, we ensure our services conform to international quality standards.”</p>
  </div>


      </React.Fragment>
    )
  }
}

export default Slider
