
    import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           
           <section className="product-details mt-50 " style={{'background-color': '#e8f8df'}}>
      <div className="container">
        <div className="row">
          <div className="col-md">
            <div className="para-details-content mobile-center text-justify justify-content-center">
              <p className="blog-pera-mrg"> Pisum is the leading dehydrated fruit exporters from India. We at Pisum believe that Indian culture, food, flowers, and such other products should be spread to the entire world. Our executives at Pisum are trained to help and support our clients at each step of the export process. With our expertise and extensive experience in the export field, we make sure that the dehydrated fruits export from India is a hassle-free process for our clients. If you are interested in export of dehydrated fruits, Contact Pisum now for more information!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
        </React.Fragment>
    );
};

export default Beverage;

