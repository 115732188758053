
// Dashboard
import { Redirect } from "react-router-dom"

import Dashboard from "../pages/Dashboard/index"
// import AboutUs from "../pages/Aboutus/index"
import React from 'react';
import Beverages from "../pages/Products/Bevarages/index"
import Tea from "../pages/Products/Bevarages/Tea/index";
import Coffee from "../pages/Products/Bevarages/Coffee/index";
import DehydratedProduct from "../pages/Products/DehydratedProduct";
import DehydVegies from "../pages/Products/DehydratedProduct/DehydrateVegies/index"
import DehydFruit from "../pages/Products/DehydratedProduct/DehydrateFruite/index"
import DryFruit from "../pages/Products/DryFruite/index";
import Almonds from "../pages/Products/DryFruite/almonds/index";
import Floriculture from "../pages/Products/Floriculture/index";
import Certificate from "../pages/Certificates/index";
import Spices from "../pages/Products/Spices/index";
import Fruits from "../pages/Products/Fruites/index";
import Vegetable from "../pages/Products/Vegetable/index";
import OilSeed from "../pages/Products/OilSeeds/index";
import Blog from "../pages/Blogs/index";
import Contactus from "../pages/Contact-us/index";
import Grains from "../pages/Products/GrainsandCeral/index";
import Pulses from "../pages/Products/Pulses/index";

const userRoutes = [
  { path: "/", component: Dashboard },
  { path: "/About", component: React.lazy(() => import('../pages/Aboutus/index'))},
  { path: "/beverages", component: Beverages },
  { path: '/beverages-tea', component: React.lazy(() => import('../pages/Products/Bevarages/Tea/index')) },
  { path: "/beverages-coffee", component: Coffee },
  { path: "/dehydratedproduct", component: DehydratedProduct },
  { path: "/dehydratedproduct-fruits", component: DehydFruit },
  { path: "/dehydratedproduct-vegies", component: DehydVegies },
  { path: "/dryfruit", component: DryFruit },
  { path: "/dryfruit-almonds", component: Almonds },
  { path: "/floriculture", component: Floriculture },
  { path: "/floriculture-alovera", component: Floriculture },
  { path: "/grains", component: Grains },
  { path: "/pulses", component: Pulses },
  { path: "/spices", component: Spices },
  { path: "/fruits", component: Fruits },
  { path: "/vegetbles", component: Vegetable },
  { path: "/oilseeds", component: OilSeed },
  { path: "/certificate", component: Certificate },
  { path: "/blog", component: Blog },
  { path: "/contact-us", component: Contactus },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/Dashboard" /> },
]

// const authRoutes = [
//   { path: "/logout", component: Logout },
//   { path: "/login", component: Login },
//   { path: "/forgot-password", component: ForgetPwd },
//   { path: "/register", component: Register },

//   { path: "/pages-maintenance", component: PagesMaintenance },
//   { path: "/pages-comingsoon", component: PagesComingsoon },
//   { path: "/pages-404", component: Pages404 },
//   { path: "/pages-500", component: Pages500 },

//   // Authentication Inner
//   { path: "/pages-login", component: Login1 },
//   { path: "/pages-login-2", component: Login2 },
//   { path: "/pages-register", component: Register1 },
//   { path: "/pages-register-2", component: Register2 },
//   { path: "/page-recoverpw", component: Recoverpw },
//   { path: "/page-recoverpw-2", component: Recoverpw2 },
//   { path: "/pages-forgot-pwd", component: ForgetPwd1 },
//   { path: "/auth-lock-screen", component: LockScreen },
//   { path: "/auth-lock-screen-2", component: LockScreen2 },
//   { path: "/page-confirm-mail", component: ConfirmMail },
//   { path: "/page-confirm-mail-2", component: ConfirmMail2 },
//   { path: "/auth-email-verification", component: EmailVerification },
//   { path: "/auth-email-verification-2", component: EmailVerification2 },
//   { path: "/auth-two-step-verification", component: TwostepVerification },
//   { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
// ]

export { userRoutes }
