import React from "react";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <React.Fragment>
         <section class="breadcrumb-area-contact mt-140">
  <div class="container">
    <div class="breadcrumb-content">
      <h2>contact us</h2>
      <ul>
        <li><Link to="/" title="home">home</Link></li>
        <li>contact us</li>
      </ul>
    </div>
  </div>
</section>
    </React.Fragment>
  );
};

export default Banner;
