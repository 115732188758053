import React from "react";
const About = (props) => {
    return (
        <React.Fragment>
            <section className="post-list">
                <article className="post">
                    <div className="blog-area pt-95">
                        <div className="container">
                            <div className="blog-mesonry">
                                <div className="row-grid">
                                    <ul>
                                        <li>
                                            <div className="col-lg-8 offset-md-2  grid-item align-items-center">
                                                <div className="blog-wrapper mb-40">
                                                    <div className="blog-img">
                                                        <a href="#"> <img src="/img/blog-img/agricultural products classification in india.jpg" alt="agricultural products classNameification in india" className="rounded mx-auto d-block"/></a>
                                                    </div>
                                                    <div className="blog-info-wrapper">
                                                        <div className="blog-meta">
                                                            <ul>
                                                                <li></li>
                                                            </ul>
                                                        </div>
                                                        <h3><a href="#">Agricultural Products Classification in India</a></h3>
                                                        <p><a href="#" title="Permalink to: Agricultural Products classNameification in India | Pisum Foods" className="read-more">The word “agriculture” comes from the Latin words “Agri” means field, and “culture” means cultivation (or) a way of life. “Agriculture is all about producing food, feed, fiber, and many other byproducts by the process of cultivating certain plants and domesticating animals.”</a></p>
                                                        {/* <a className="blog-btn btn-hover" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="post">
                    <div className="blog-area pt-95">
                        <div className="container">
                            <div className="blog-mesonry">
                                <div className="row-grid">
                                    <ul>
                                        <li>
                                            <div className="col-lg-8 offset-md-2  grid-item align-items-center">
                                                <div className="blog-wrapper mb-40">
                                                    <div className="blog-img">
                                                        <a href="#"> <img src="/img/blog-img/sustainable%20eating%20habits.jpg" alt="sustainable eating habits" className="rounded mx-auto d-block"/></a>
                                                    </div>
                                                    <div className="blog-info-wrapper">
                                                        <div className="blog-meta">
                                                            <ul>
                                                                <li></li>
                                                            </ul>
                                                        </div>
                                                        <h3><a href="#">Sustainable Eating Habits - Factors and Importance</a></h3>
                                                        <p><a href="#" title="Permalink to: Sustainable Eating Habits - Factors and Importance | Pisum Foods" className="read-more">Due to climate change being recognized as a problem, we as humans have started preserving the Planet by using recycled things like glass, paper, compost, organic waste, reusable bags, and so forth. Food is a common substance produced by the Planet and consumed by us. </a></p>
                                                        {/* <a className="blog-btn btn-hover" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="post">
                    <div className="blog-area pt-95">
                        <div className="container">
                            <div className="blog-mesonry">
                                <div className="row-grid">
                                    <ul>
                                        <li>
                                            <div className="col-lg-8 offset-md-2  grid-item align-items-center">
                                                <div className="blog-wrapper mb-40">
                                                    <div className="blog-img">
                                                        <a href="#"> <img src="/img/blog-img/Clove%20Benefits%20Side%20Effects%20and%20Details.jpg" alt="Clove Benefits Side Effects and Details" className="rounded mx-auto d-block"/></a>
                                                    </div>
                                                    <div className="blog-info-wrapper">
                                                        <div className="blog-meta">
                                                            <ul>
                                                                <li></li>
                                                            </ul>
                                                        </div>
                                                        <h3><a href="#">Clove - Benefits, Side Effects & Details</a></h3>
                                                        <p><a href="#" title="Permalink to: Clove - Benefits, Side Effects & Details | Pisum Foods" className="read-more">It all started in the third century BC when the Emperors of the Han Dynasty in China, demanded that people who speak to them should have fresh breath. What could help the case?- cloves. For decades and decades, clove is used for human health and is a cure for toothache.</a></p>
                                                        {/* <a className="blog-btn btn-hover" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="post">
                    <div className="blog-area pt-95">
                        <div className="container">
                            <div className="blog-mesonry">
                                <div className="row-grid">
                                    <ul>
                                        <li>
                                            <div className="col-lg-8 offset-md-2  grid-item align-items-center">
                                                <div className="blog-wrapper mb-40">
                                                    <div className="blog-img">
                                                        <a href="organic-contributes-to-planet.html"> <img src="/img/blog-img/organic%20is%20the%20new%20green.jpg" alt="organic is the new green" className="rounded mx-auto d-block"/></a>
                                                    </div>
                                                    <div className="blog-info-wrapper">
                                                        <div className="blog-meta">
                                                            <ul>
                                                                <li></li>
                                                            </ul>
                                                        </div>
                                                        <h3><a href="organic-contributes-to-planet.html">ORGANIC IS THE NEW GREEN - BENEFITS AND DETAILS</a></h3>
                                                        <p><a href="organic-contributes-to-planet.html" title="Permalink to: Organic is the new Green - Benefits and Details | Pisum Foods" className="read-more">In Tamil Nadu state, most of the dishes are prepared from the curry leaves. The reason is its smell, medicinal properties, and taste. That is why, these curry leaves have always been valued in the Indian markets. Nearly, hundred acres of land is being cultivated under curry leaves.</a></p>
                                                        {/* <a className="blog-btn btn-hover" href="organic-contributes-to-planet.html">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

            </section>
        </React.Fragment>
    );
};

export default About;
