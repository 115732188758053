

import React, { useEffect } from "react";
import { appendScript } from "../../Util/script";
import { Link } from "react-router-dom";

const Banner = (props) => {
    useEffect(()=>{
        appendScript('js/search-product.js')
    })
  return (
    <React.Fragment>
  <div className="wrapper mrl-80">
    <div className="sub-listing-area pt-40 pb-90">
      <div className="container">
        <ul className="list row" style={{'list-style-type': 'none !important'}}>
          <li className="col-xl-3 col-md-4">
            <div className="product-fruit-wrapper mb-30">
              <Link to="/beverages-tea">
                <div className="product-fruit-img">
                  <img className="lazyload" data-src="/img/beverages/tea.png" alt="tea"/>
                </div>
              </Link>
              <div className="product-fruit-content text-center">
                <h4><Link to='/beverages-tea'  className="name" title="tea">tea</Link></h4>
              </div>
            </div>
          </li>
        
          <li className="col-xl-3 col-md-4">
            <div className="product-fruit-wrapper mb-30">
              <Link to="/beverages-coffee">
                <div className="product-fruit-img">
                  <img className="lazyload" data-src="/img/beverages/coffe.png" alt="Coffee"/>
                </div>
              </Link>
              <div className="product-fruit-content text-center">
                <h4><Link to='/beverages-coffee'  className="name" title="Coffee" >Coffee</Link></h4>
              </div>
            </div>
          </li>                              
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Banner;
