import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{'list-style-type':'none !important'}}>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/40033819-2_6-fresho-apple-shimla.png" alt="Apple"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Apple" href="#">Apple</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/efs26426-fig-0005-m.png" alt="Banana"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Banana" href="#">Banana</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/imam-pasand-mango-fruit-500x500.png" alt="Mango"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Mango" href="#">Mango</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/Nagpur_orange_article.png" alt="Orange"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Orange" href="#">Orange</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/Manila-2951-1024x682.png" alt="Pineapple"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Pineapple" href="#">Pineapple</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/Pomegranates_web_large_1_af3a7306-36c7-437b-818a-cc4097fa2c6d_1200x.png" alt="Pomegranate"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Pomegranate" href="#">Pomegranate</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/Dragon-Fruit.png" alt="Dragon Fruit"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Dragon Fruit" href="#">Dragon Fruit</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/_ccbcbe9a-fd17-11e8-9457-b1b429387a4e.png" alt="Strawberry"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Strawberry" href="#">Strawberry</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/165373-849x565-grapes.png" alt="Grapes"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Grapes" href="#">Grapes</a></h4>
              </div>
            </div>
          </li>
         
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/guva.png" alt="Guava"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Guava" href="#">Guava</a></h4>
              </div>
            </div>
          </li>
       
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/pears1.png" alt="Litchi"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Litchi" href="#">Litchi</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/41gtX+K8zoL.png" alt="Papaya"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Papaya" href="#">Papaya</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/melon.png" alt="Watermelon"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Watermelon" href="#">Watermelon</a></h4>
              </div>
            </div>
          </li>
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Fruits/coconut11597249835.png" alt="Coconut"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Coconut" href="#">Coconut</a></h4>
              </div>
            </div>
          </li>                  
          
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
