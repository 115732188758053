import React from "react";
import Banner from "./banner";
import Expxort from "./export";
import ProductList from "./productlist"
const Search = (props) => {
   
  return (
    <React.Fragment>
      <Banner/>
      <Expxort/>
      <ProductList/>
    </React.Fragment>
  );
};

export default Search;
