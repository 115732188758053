import React from "react";
const Banner = (props) => {
  return (
    <React.Fragment>
        <section className="pb-105 mrl-80">
  <div className="container">
    <div className="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Beverages Exporters</h2>
    </div>
      <p className="text-dark text-justify"> India is known for its two most popular beverages across the world, Tea and Coffee. There are also some other beverages in India that are famous worldwide including Lassi, Sholkadi, Buttermilk so on, and so forth. Beverage export from India is now a growing component of the food export industry from India. Pisum Foods is one of the best beverage importers from India. Export of beverages like tea and coffee and their various types can be done through us.</p>
      <p className="text-dark text-justify" id="space"> Being a well-experienced beverage exporter in India, Pisum is well equipped to help you at every stage of the process. Export of Beverages includes documentation, finding buyers, quality tests, and such other processes. Our trained executives at Pisum will assist you with each of these processes involved in the export of Indian beverages. Through our high-quality products, high level of hygiene, and safety we meet international food safety and quality standards. Therefore, Pisum Foods is known to be one of the most reliable beverage exporters. If you are in search of beverages import from India, Pisum is here to serve you!</p>
  </div>
</section>

    </React.Fragment>
  );
};

export default Banner;
