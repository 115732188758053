import React, { useEffect } from "react";
import { appendScript } from "../Util/script";
const Search = (props) => {
    useEffect(()=>{
appendScript('js/search-product.js')
    },[])
  return (
    <React.Fragment>
<div className="container">
    <div className="d-flex justify-content-center h-100">
      <div className="searchbar">
        <input className="search_input search" type="text" name="" placeholder="Search product"/>
        <a title="search icon" href="javascript:void(0);" className="search_icon"><i className="fa fa-search"></i></a>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
