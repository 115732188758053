import React from "react";
const About = (props) => {
  return (
    <React.Fragment>
      <section className="certification-area ptb-60 mrl-70">
      <div className="certificate-gallery container">
        <div className="section-title-furits text-center mb-80">
          <img className="lazyload" data-src="/img/icon-img/49.png" alt=""/>
          <h2>Certifications</h2>
          <div className="paragraph">
            <p className="text-justify pt-30">When it comes to food handling and export, maintaining high levels of hygiene, quality and safety are crucial. This is why, at Pisum, we meet international food safety and quality standards through various quality checks and following strict protocols throughout the process. This has allowed us to be accredited to a number of quality and food safety certifications.</p>
            <p className="text-justify pt-30">Being accredited to TPCI, APEDA, and other certifications, we make sure the entire process meets the top quality and food safety standards. Be it the food handling, packaging, or processing, we make sure that everything is done in accordance with the stringent protocols. We are an Indian government-certified exporter of food products.</p>
          </div>
        </div>      

        {/* <div id="lightgallery" className="row">

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="/img/certificates/APEDA.jpg">

            <a href="javascript:void(0);" title="APEDA">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-APEDA.jpg"
                alt="APEDA"/>

            </a>
            <p className="text-center">APEDA </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/FIEO.jpg">

            <a href="javascript:void(0);" title="FIEO">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-FIEO.jpg"
                alt="FIEO"/>

            </a>
            <p className="text-center">FIEO </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/Incorporation.jpg">

            <a href="javascript:void(0);" title="Certificate of Incorporation">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb_Incorporation.jpg"
                alt="Certificate of Incorporation"/>

            </a>
            <p className="text-center">Certificate of Incorporation </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/GST.jpg">

            <a href="javascript:void(0);" title="GST">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb_GST.jpg"
                alt="GST"/>

            </a>
            <p className="text-center">GST </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/MSME.jpg">

            <a href="javascript:void(0);" title="MSME">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-MSME.jpg"
                alt="MSME"/>

            </a>
            <p className="text-center">MSME </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/IEC.jpg">

            <a href="javascript:void(0);" title="IEC">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-IEC.jpg"
                alt="IEC"/>

            </a>
            <p className="text-center">IEC </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/IIFT.jpg">

            <a href="javascript:void(0);" title="IIFT">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-IIFT.jpg"
                alt="IIFT"/>

            </a>
            <p className="text-center">IIFT </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/TBL.jpg">

            <a href="javascript:void(0);" title="Tea Board License">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-TBL.jpg"
                alt="Tea Board License"/>

            </a>
            <p className="text-center">Tea Board License </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/coconut-development-board.jpg">

            <a href="javascript:void(0);" title="Coconut Development board">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-coconut-development-board.jpg"
                alt="Coconut Development board"/>

            </a>
            <p className="text-center">Coconut Development board </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/coffee-board.jpg">

            <a href="javascript:void(0);" title="Coffee Board">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-coffee-board.jpg"
                alt="Coffee Board"/>

            </a>
            <p className="text-center">Coffee Board </p>
            
          </div>


          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/iso-9001-2015.jpg">

            <a href="javascript:void(0);" title="ISO 9001:2015">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb_iso-9001-2015.jpg"
                alt="ISO 9001:2015"/>

            </a>
            <p className="text-center">ISO 9001:2015 </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/FSSAI.png">

            <a href="javascript:void(0);" title="FSSAI License <br> No. 10020022011728">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/Thumb_FSSAI.png"
                alt="FSSAI License <br> No. 10020022011728"/>

            </a>
            <p className="text-center">FSSAI License No. 10020022011728 </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/EXIM_cert.png">

            <a href="javascript:void(0);" title="EXIM Membership of the <br> India Chamber of Commerce">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/EXIM_thumb.png"
                alt="EXIM Membership of the <br> India Chamber of Commerce"/>

            </a>
            <p className="text-center">EXIM Membership of the  India Chamber of Commerce </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="/img/certificates/dun & bradstreet.png">

            <a href="javascript:void(0);" title="dun & bradstreet">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/Thumb_dun%20%26%20bradstreet.png"
                alt="dun & bradstreet"/>

            </a>
            <p className="text-center">dun & bradstreet </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/spices-board.jpg">

            <a href="javascript:void(0);" title="Spices Board">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-spices-board.jpg"
                alt="Spices Board"/>

            </a>
            <p className="text-center">Spices Board </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/TPCI.jpg">

            <a href="javascript:void(0);" title="TPCI">
              
              <img
                className="mx-auto d-block"
                src="img/certificates/thumb-TPCI.jpg"
                alt="TPCI"/>

            </a>
            <p className="text-center">TPCI </p>
            
          </div>

        

          <div  className="col-md-3 col-sm-6 single-certificate lazyload"
                data-src="img/certificates/CYDA-appreciation-letter.jpg">

            <a href="javascript:void(0);" title="cyda"></a>
              
          </div>

        
        </div> */}
      </div>
    </section>

    </React.Fragment>
  );
};

export default About;
