import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
 <div class="breadcrumb-area-listing fruits mt-140">
  <div class="container">
    <div class="breadcrumb-content">
      <h2>Fruits</h2>
      <ul>
        <li><a title="Home" href="index.html">home</a></li>
        <li> Fruits </li>
      </ul>
    </div>
  </div>
</div>
    </React.Fragment>
  );
};

export default Search;
