import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
        
<section className="product-details mt-50">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="product-details-content text-center mobile-center">
          <h1>Indian Tea Exporters</h1>
          <p className="mt-50 text-justify ">Indian tea is among the best known in the world. India exported tea worth 535.13 million in 2019-202. India is the fourth largest tea exporter in the world. Tea is grown in several states in India including the Nilgiris, Assam, and Darjeeling. Not only is India one of the largest tea exporters but also one of the largest tea consumers around the world. You can find a tea exporter in India.</p>
        </div>
        <div className="goal-wrapper mb-30 mobile-center prl-30">
          <h3></h3>
          <p className="text-justify "></p>
        </div>
      </div>
    </div>
  </div>
</section>
        </React.Fragment>
    );
};

export default Beverage;
