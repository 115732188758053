import React from "react";
const Search = (props) => {

    return (
        <React.Fragment>
           <section className="product-basmati pb-40">
      <div className="product-details-content text-center mobile-center">
        <h2 className="pt-40">Coffee Varieties We Export</h2>
        <p className="mt-50 text-justify prl-60">The two broad species of coffee are Coffee Arabica and Coffee Robusta. Coffee Arabica is the most popular coffee in the world while Coffee Robusta is the second most popular coffee across the globe. Besides these two broad categories, there are over 100 coffee species across the world. Coffee export from India is mainly conducted from the southern states of the country.</p>
      </div>
      <div className="container">
        <div className="variety-holder prl-40">
          
          <div className="single-fruits-choose landscape-single-fruit">
            
            <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>1</h3>
                  </div>
            <div className="goal-wrapper mb-30 mobile-center variety-content">
              <h3>Arabica</h3>
              <p className="text-justify">Arabian Coffee popularly known as Arabica is a famous variety of coffee. It is a type of coffee that is made from the beans of the Coffee Arabica plant. This coffee is said to be originated from Ethiopia. Arabica has a mildly sweet flavor but you may also notice hints of fruits, berries, chocolate, nuts, caramel, and so on.</p>
            </div>
          </div>
          
          <div className="single-fruits-choose landscape-single-fruit">
            
            <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>2</h3>
                  </div>
            <div className="goal-wrapper mb-30 mobile-center variety-content">
              <h3>Robusta</h3>
              <p className="text-justify">Coffea canephora or Robusta is the second most popular species of coffee. Robusta is said to be beneficial for the patients of asthma. Sometimes, it is also used to cure headaches or migraines. The bean is also said to be good for the skin. Robusta is also rich in antioxidants which improve your overall health.</p>
            </div>
          </div>
          
          <div className="single-fruits-choose landscape-single-fruit">
            
            <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>3</h3>
                  </div>
            <div className="goal-wrapper mb-30 mobile-center variety-content">
              <h3>Typica</h3>
              <p className="text-justify">Typica is also a type of Arabica Coffee. Typica is one of the most popular coffees in the world. Originated in Ethiopia, it is now grown in several countries across the world. It has been genetically modified to produce new characteristics.</p>
            </div>
          </div>
          
          <div className="single-fruits-choose landscape-single-fruit">
            
            <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>4</h3>
                  </div>
            <div className="goal-wrapper mb-30 mobile-center variety-content">
              <h3>Bourbon</h3>
              <p className="text-justify">Bourbon is a type of coffee under the Coffee Arabica species. It is a famous type of coffee and is characterized by tall plants. Originated in Yemen, Bourbon is said to help to reduce stress and relax your mind. It is said to aid weight loss and improves heart health. It also helps to keep cholesterol levels.</p>
            </div>
          </div>
          
          <div className="single-fruits-choose landscape-single-fruit">
          <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>5</h3>
                  </div>
            <div className="goal-wrapper mb-30 mobile-center variety-content">
              <h3>Gesha/Geisha</h3>
              <p className="text-justify">Gesha or Geisha is a variety of coffee under the Arabica species. It is an expensive coffee and is said to have originated from Ethiopia. It is a rare type of coffee and is considered exclusive. It has a very unique and delicate texture. It is said to be one of the most incredible Arabica coffees.</p>
            </div>
          </div>
          
        </div>
        </div>
      </section>

        </React.Fragment>
    );
};

export default Search;
