import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{"list-style-type":" none !important"}}>
                                     
         <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/soyabean-getty-1200-600x450.png" alt="Soya bean"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Soya bean" href="#">Soya bean</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/arhar-500x500.png" alt="Pigeon pea/Arhar/Tur"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Pigeon pea/Arhar/Tur" href="#">Pigeon pea/Arhar/Tur</a></h4>
              </div>
            </div>
          </li>                                                                                                                  
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/masoor-dal-28red-lentil-29-500x500.png" alt="Masoor Dal / Red Lentil"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Masoor Dal / Red Lentil" href="#">Masoor Dal / Red Lentil</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/green-gram-mung-dal-mung-beans-500x500.png" alt="Green gram / Mung bean"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Green gram / Mung bean" href="#">Green gram / Mung bean</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/black-gram-black-whole-urad-dal-500x500.png" alt="Black gram"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Black gram" href="#">Black gram</a></h4>
              </div>
            </div>
          </li>
                                    
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/beaten-rice-500x500.png" alt="Beaten rice"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Beaten rice" href="#">Beaten rice</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/field-bean-500x500.png" alt="Field beans"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Field beans" href="#">Field beans</a></h4>
              </div>
            </div>
          </li>
                                        
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/turkish-gram-moth-bean-500x500.png" alt="Turkish gram / Moth bean"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Turkish gram / Moth bean" href="#">Turkish gram / Moth bean</a></h4>
              </div>
            </div>
          </li>
        
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Pulses/urad-gota-whole-500x500.png" alt="Urad Dal Gota"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Urad Dal Gota" href="#">Urad Dal Gota</a></h4>
              </div>
            </div>
          </li>
          
          
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
