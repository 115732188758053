import React from 'react';
import { Link } from "react-router-dom";

const Footer =()=>{
  return(
    <footer class="footer-area fruits-footer">
    <div class="food-footer-bottom pt-80 pb-70 black-bg-5">
      <div class="container">
        <div class="row mrl-20">
          <div class="col-md-6 col-lg-3 footer-section">
            <div class="footer-widget">
              <h3 class="footer-widget-title-6">Information</h3>
              <div class="food-widget-content">
                <ul style={{'list-style': 'none'}}>
                   
                  <li>
                    <Link
                      to="/"
                      title="Home"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Home</Link>
                  </li>
                    
                  <li>
                    <Link
                      to="/About"
                      
                      title="About Us"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; About Us
                    </Link>
                  </li>
                      
                  <li>
                    <Link
                      to="/certificate"
                      
                      title="Certificates"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Certificates</Link>
                  </li>
                    
                  <li>
                    <Link
                      to="/"                      
                      title="Products Range"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Products Range</Link>
                  </li>
                 
                  <li>
                    <Link
                     to="/blog"
                      
                      title="Blogs"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Blogs</Link>
                  </li>
                    
                  
                    
                  <li>
                    <Link
                      to="/contact-us"
                      
                      title="Contact Us"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Contact Us</Link>
                  </li>
                   
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 footer-section">
            <div class="footer-widget">
              <h3 class="footer-widget-title-6">Products Range</h3>
              <div class="food-widget-content">
                <ul style={{'list-style': 'none'}}>
                       
                  <li>
                    <a href="grains-and-cereal/index.html" title="Grains and Cereal">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Grains and Cereal</a
                    >
                  </li>
                  
                  <li>
                    <a href="pulses/index.html" title="Pulses">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Pulses</a
                    >
                  </li>
                  
                  <li>
                    <a href="spices/index.html" title="Spices">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Spices</a
                    >
                  </li>
                  
                  <li>
                    <a href="fruits/index.html" title="Fruits">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Fruits</a
                    >
                  </li>
                  
                  <li>
                    <a href="vegetables/index.html" title="Vegetables">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Vegetables</a
                    >
                  </li>
                  
                  <li>
                    <a href="oilseeds/index.html" title="Oilseeds">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Oilseeds</a
                    >
                  </li>
                  
                  <li>
                    <a href="floriculture/index.html" title="Floriculture">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Floriculture</a
                    >
                  </li>
                  
                  <li>
                    <a href="herbs/index.html" title="Herbs">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Herbs</a
                    >
                  </li>
                  
                  <li>
                    <a href="dry-fruits/index.html" title="Dry Fruits">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Dry Fruits</a>
                  </li>
                  
                  <li>
                    <a href="beverages/index.html" title="Beverages">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Beverages</a
                    >
                  </li>
                  
                  <li>
                    <a href="dehydrated-products/index.html" title="Dehydrated Products">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Dehydrated Products</a
                    >
                  </li>
                               
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 footer-section">
             <div class="footer-widget">
              <h3 class="footer-widget-title-6">Branches</h3>
              <div class="food-widget-content">
                <ul style={{'list-style': 'none'}}>                  
                  <li>
                    <a
                      href="#branches"
                      title="Pune"
                    >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      &nbsp; Pune
                    </a>
                  </li>                                   
                 
                  
                </ul>
              </div>
            </div> 
          </div>
          <div class="col-md-6 col-lg-3 footer-section">
            <div class="footer-widget">
              <h3 class="footer-widget-title-6">Stay Connected</h3>
              <div class="food-about">
                <div class="food-about-info">
                  <div class="food-info-wrapper">
                    <div class="food-address">
                      <div class="food-info-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      <div class="food-info-content">
                        <h6>Registered address:</h6>
                        <p>
                        MUYONI TRADING LLP.<br/>
Planet Millennium | No. 502, | Bldg. A/15<br/>
S. No. 74 | Pimple Saudagar<br/>
Pune-411027 Maharashtra State, India<br/>
                        </p>
                        
                      </div>
                    </div>
                    <div class="food-address">
                      <div class="food-info-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      <div class="food-info-content">
                        <h6>Registered Branch:</h6>
                        <p>
                        Bhawani peth maddi wasti,<br/>
near Datta Mandir,<br/>
Solapur.<br/>


                        </p>
                       
                      </div>
                    </div>
                                   
                    <div class="food-address">
                      <div class="food-info-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>
                      <div class="food-info-content">
                        <h6>Phone Number:</h6>
                        <p> +91 91581 24599 <br/>
                         +91 89993 63423 </p>
                      </div>
                    </div>                  
                    <div class="food-address">
                      <div class="food-info-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                      <div class="food-info-content">
                        <h6>Email:</h6>
                        <p>
                          <a
                            title="Pisum Foods Email"
                            href="mailto:sales@vitessetec.com"
                            >sales@vitessetec.com</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-20">
            <nav class="social-bar container">
              
              <a
                class="fb text-yt text-yt"
                title="youtube icon"
                target="_blank"
                rel="noopener"
                href="#"
                ><i class="fa fa-youtube"></i>
              </a>
              
              <a
                class="fb text-fb text-fb"
                title="facebook icon"
                target="_blank"
                rel="noopener"
                href="#"
                ><i class="fa fa-facebook"></i>
              </a>
              
              <a
                class="fb text-tw text-tw"
                title="twitter icon"
                target="_blank"
                rel="noopener"
                href="#"
                ><i class="fa fa-twitter"></i>
              </a>
              
              <a
                class="fb text-ld text-ld"
                title="linkedin icon"
                target="_blank"
                rel="noopener"
                href="../www.linkedin.com/company/pisum-food-services.html"
                ><i class="fa fa-linkedin"></i>
              </a>
              
              <a
                class="fb text-ig text-ig"
                title="instagram icon"
                target="_blank"
                rel="noopener"
                href="../www.instagram.com/pisumfoods/index.html"
                ><i class="fa fa-instagram"></i>
              </a>
              
            </nav>
          </div>
        </div>
      </div>
    </div>
    {/* <div class="food-copyright black-bg-6 ptb-30">
      <div class="container text-center">
        <p>
          Copyright © 2020
          <a
            style={{textDecoration: 'underline'}}
            target="_blank"
            rel="noopener"
            href="../www.btwgroup.co/index.html"
            title="btw group"
          >
            A BTW Group Company </a
          >&nbsp; | &nbsp;Designed by
          <a
            style={{textDecoration: 'underline'}}
            title="wbgl india"
            target="_blank"
            rel="noopener"
            href="../wgbl.co/index.html"
            >WGBL India
          </a>
          - Design Agency
        </p>
      </div>
    </div> */}
  </footer>
  
  )
}
export default Footer;