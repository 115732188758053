import React ,{useEffect}from "react"

import { Switch, BrowserRouter } from "react-router-dom"

import { userRoutes } from "./routes/allRoutes"

import Authmiddleware from "./routes/middleware/Authmiddleware"

import MainLayout from "./components/MainLayout/index"

const App = props => {

  
function getLayout() {
    let layoutCls = MainLayout
     
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
     <BrowserRouter>
  <Switch>
 
          {userRoutes.map((route, idx) => (
          //  console.log("User ROutes Are",route)
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  )
}


export default App;
// import React from 'react';
// countries : [
// 	{ name: 'Germany', states: [ {name: 'A', cities: ['Duesseldorf', 'Leinfelden-Echterdingen', 'Eschborn']} ] },
// 	{ name: 'Spain', states: [ {name: 'B', cities: ['Barcelona']} ] },
// 	{ name: 'USA', states: [ {name: 'C', cities: ['Downers Grove']} ] },
// 	{ name: 'Mexico', states: [ {name: 'D', cities: ['Puebla']} ] },
// 	{ name: 'India', states: [ {name: 'E', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']},{name: 'F', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']},{name: 'G', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']} ] },
// ]
// var newresponse={
// 	by_project:[
// 	   {
// 		  project:'ZillionX-Master',
// 		  total_cpu_utilization:'68',
// 		  by_zone:[
// 			 {
// 				zone: 'us-central1-a',
	
// 				avg_utilization: 641950.25,
		  
// 				id:'19',
// 				by_instance:[
// 				   {
// 					  instance_name:'autoviz-1-a',
// 					  percentage:'37'
// 				   },
// 				   {
// 					  instance_name:'autoviz-1-b',
// 					  percentage:'37'
// 				   },
// 				]
// 			 },
// 			 {
// 				zone: 'us-west1-b',
	
// 				avg_utilization: 268.36,
		  
// 				id:'41',
// 				by_instance:[
// 				   {
// 					  instance_name:'autoviz-2-a',
// 					  percentage:'37'
// 				   },
// 				   {
// 					  instance_name:'autoviz-2-b',
// 					  percentage:'37'
// 				   }
// 				]
// 			 },
// 			 {
// 				zone: 'us-east1-b',
	
// 				avg_utilization: 44310.6,
		  
// 				id:'06',
// 				by_instance:[
// 				   {
// 					  instance_name:'autoviz-2-a',
// 					  percentage:'37'
// 				   },
// 				   {
// 					  instance_name:'autoviz-2-b',
// 					  percentage:'37'
// 				   }
// 				]
// 			 },
// 		  ]
// 	   },
// 	   {
// 		project:'studybot',
// 		total_cpu_utilization:'68',
// 		by_zone:[
// 		   {
// 			zone: 'us-west3-a',
	
// 		  avg_utilization: 268.36,
	
// 		  id:'49',
// 			  by_instance:[
// 				 {
// 					instance_name:'autoviz-1-a',
// 					percentage:'37'
// 				 },
// 				 {
// 					instance_name:'autoviz-1-b',
// 					percentage:'37'
// 				 },
// 			  ]
// 		   },
// 		   {
// 			zone: 'us-east3-a',
	
// 			avg_utilization: 33268.36,
	  
// 			id:'45',
// 			  by_instance:[
// 				 {
// 					instance_name:'autoviz-2-a',
// 					percentage:'37'
// 				 },
// 				 {
// 					instance_name:'autoviz-2-b',
// 					percentage:'37'
// 				 }
// 			  ]
// 		   },
// 		   {
// 			zone: 'us-west4-a',
	
// 		  avg_utilization: 33268.36,
	
// 		  id:'32',
// 			  by_instance:[
// 				 {
// 					instance_name:'autoviz-2-a',
// 					percentage:'37'
// 				 },
// 				 {
// 					instance_name:'autoviz-2-b',
// 					percentage:'37'
// 				 }
// 			  ]
// 		   },
// 		]
// 	 }
	   
// 	]
//  }
// class Dropdown extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			countries : [],
// 			states : [],
// 			cities : [],
// 			selectedCountry : '--Choose Country--',
// 			selectedState : '--Choose State--'
// 		};
// 		this.changeCountry = this.changeCountry.bind(this);
// 		this.changeState = this.changeState.bind(this);
// 	}
  
// 	componentDidMount() {
// 		this.setState({
// 			countries : [
// 				{ name: 'Germany', states: [ {name: 'A', cities: ['Duesseldorf', 'Leinfelden-Echterdingen', 'Eschborn']} ] },
// 				{ name: 'Spain', states: [ {name: 'B', cities: ['Barcelona']} ] },
// 				{ name: 'USA', states: [ {name: 'C', cities: ['Downers Grove']} ] },
// 				{ name: 'Mexico', states: [ {name: 'D', cities: ['Puebla']} ] },
// 				{ name: 'India', states: [ {name: 'E', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']},{name: 'F', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']},{name: 'G', cities: ['Delhi', 'Kolkata', 'Mumbai', 'Bangalore']} ] },
// 			]
// 		});
// 	}
	
// 	changeCountry(event) {
// 		this.setState({selectedCountry: event.target.value});
// 		this.setState({states : this.state.countries.find(cntry => cntry.name === event.target.value).states});
// 	}

// 	changeState(event) {
// 		this.setState({selectedState: event.target.value});
// 		const stats = this.state.countries.find(cntry => cntry.name === this.state.selectedCountry).states;
// 		this.setState({cities : stats.find(stat => stat.name === event.target.value).cities});
// 	}
	
// 	render() {
// 		return (
// 			<div id="container">
// 				<h2>Cascading or Dependent Dropdown using React</h2>
	
// 				<div>
// 					<label>Country</label>
// 					<select placeholder="Country" value={this.state.selectedCountry} onChange={this.changeCountry}>
// 						<option>--Choose Country--</option>
// 						{this.state.countries.map((e, key) => {
// 							return <option key={key}>{e.name}</option>;
// 						})}
// 					</select>
// 				</div>

// 				<div>
// 					<label>State</label>
// 					<select placeholder="State" value={this.state.selectedState} onChange={this.changeState}>
// 						<option>--Choose State--</option>
// 						{this.state.states.map((e, key) => {
// 							return <option key={key}>{e.name}</option>;
// 						})}
// 					</select>
// 				</div>
				
// 				<div>
// 					<label>City</label>
// 					<select placeholder="City">
// 						<option>--Choose City--</option>
// 						{this.state.cities.map((e, key) => {
// 							return <option key={key}>{e}</option>;
// 						})}
// 					</select>
// 				</div>
// 			</div>
// 		)
// 	}
// }

// export default Dropdown;


