import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
 <section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Oilseeds Exporters</h2>
    </div>
      <p class="text-dark text-justify"> Oilseeds are basically crops which are specially grown to extract oil from its seeds. The major oilseeds in India include soybean, sunflower, Brassica, canola, coconut, oil palm, rapeseed, peanuts, rice, and cotton. Oilseeds export from India is one of the major export areas today. India is known to be one of the largest oilseeds exporters with a large share of export of groundnuts and sesame seeds. Pisum Foods is one of the leading oil seeds importers from India. We are known across the world for the export of oilseeds and such other premium quality food products.</p>
      <p class="text-dark text-justify" id="space"> Being one of the most experienced oilseeds exporters in India, we help our clients at every step on the way. From getting clients for your products until your products reach the buyer, Pisum Foods will assist you with everything. We make sure that the export of Indian oilseeds is conducted and completed in an organized manner. If you are looking for oil seeds import from India, we at Pisum Foods are here to assist you!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Search;
