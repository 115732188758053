import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Dry Fruits Exporters</h2>
    </div>
      <p class="text-dark text-justify"> Dry fruits in India are basically fruits whose water content has been removed almost completely either naturally or by processes like sun drying or specialized dehydrators or dryers. Dry fruits can be eaten in various forms and are known to be powerhouses of nutrients. Dry fruits export from India includes the export of dates, raisins, almonds, cashew, apricot, and so on and so forth. Pisum Foods is one of the best dry fruits importers from India. We work on the export of dry fruits from India to many countries in the world.</p>
      <p class="text-dark text-justify" id="space"> We at Pisum Foods hold an extensive experience in the export of Indian dry fruits. As one of the leading dry fruits exporter in India, we make sure that our clients face no issues from documentation to the final delivery of the products. By conducting an in-depth analysis of the international market, we keep our clients well informed about the latest developments and how it may affect their exports. Pisum is one of the most reliable dry fruits exporters and also helps you to find buyers for your products globally. If you are looking for dry fruits import from India, Pisum Foods is here to help you at every step!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Search;
