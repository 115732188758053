import React, { Component } from "react";

class Slider extends Component {
  render() {
    return (
      <React.Fragment>
        <div class="container">
          <div class="section-title-furits text-center mb-95">
            <img
              class="lazyload"
              data-src="img/icon-img/leaf (1).png"
              alt="Product range"
            />
            <h2>Our Product Range</h2>
          </div>
          <div class="row mrl-20">
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/grains-and-cereal/2436498+Wheat li jingwang istockphoto.png"
                      alt="Grains and Cereal"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a
                      title="Grains and Cereal"
                      href="#"
                    >
                      Grains and Cereal
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Pulses/masoor-dal-28red-lentil-29-500x500.png"
                      alt="Pulses"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Pulses" href="#">
                      Pulses
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Spices/mixspices.png"
                      alt="Spices"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Spices" href="#">
                      Spices
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Fruits/Dragon-Fruit.png"
                      alt="Fruits"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Fruits" href="#">
                      Fruits
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Vegetables/coloredCarrots-022521-LD-770x533-1-745x490.png"
                      alt="Vegetables"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Vegetables" href="#">
                      Vegetables
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Oilseeds/Vernonia-cinerea-a-Habit-b-Anthesing-capitula-c-Anthesis-in-individual-capitula-d.png"
                      alt="Oilseeds"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Oilseeds" href="#">
                      Oilseeds
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Floriculture/511IBS0CS2L.png"
                      alt="Floriculture"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Floriculture" href="#">
                      Floriculture
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6">
              <div class="product-fruit-wrapper mb-30">
                <a href="#">
                  <div class="product-fruit-img">
                    <img
                      class="lazyload"
                      data-src="/img/Herbs/tulsi_4979848_835x547-m.png"
                      alt="Herbs"
                    />
                  </div>
                </a>
                <div class="product-fruit-content text-center">
                  <h4>
                    <a title="Herbs" href="#">
                      Herbs
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <a href="#">
              <button type="button" class="range-button">
                {" "}
                View More
              </button>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Slider;
