import React from "react";
import Banner from "./banner"
import Export from "./export";
import Ingredient from "./ingredients";
import Varities from "./varities"
const Beverage = (props) => {
    return (
        <React.Fragment>
           <Banner/>
           <Export/>
           <Ingredient/>
           <Varities/>
        </React.Fragment>
    );
};

export default Beverage;
