import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{'list-style-type': 'none !important'}}>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/Red-Onion2-667x400.png" alt="Onion"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Onion" href="#">Onion</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/mushrooms.png" alt="Mushroom"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Mushroom" href="#">Mushroom</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/Adding-broccoli-meal-to-poultry-feed-may-boost-egg-yolk-color_wrbm_large.png" alt="Broccolie"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Broccolie" href="#">Broccolie</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Vegetables/potato.png" alt="Potato"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Potato" href="#">Potato</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/English_Spinach__67562_1024x1024@2x.png" alt="Spinach / Palak"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Spinach / Palak" href="#">Spinach / Palak</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/cabbage-crops.png" alt="Cabbage"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Cabbage" href="#">Cabbage</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/421202194321AM.png" alt="Brinjal"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Brinjal" href="#">Brinjal</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/cauliflower-is-rich-in-nutrients-and-fiber.png" alt="Cauliflower"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Cauliflower" href="#">Cauliflower</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/nurserylive-seeds-moringa-oleifera-drum-stick-rama-pk-115-vegetable-seeds-16969035481228_600x600.png" alt="Drum Stick"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Drum Stick" href="#">Drum Stick</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/lady-finger-500x500.png" alt="Lady Finger"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Lady Finger" href="#">Lady Finger</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/green-chilli_759.png" alt="Green Chilli"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Green Chilli" href="#">Green Chilli</a></h4>
              </div>
            </div>
          </li>
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/202770-bitter-gourd.png" alt="Bitter Gourd"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Bitter Gourd" href="#">Bitter Gourd</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/image.png" alt="Cucumber"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Cucumber" href="#">Cucumber</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/61nKoNP3fWL._SL1200_.png" alt="Pumpkin"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Pumpkin" href="#">Pumpkin</a></h4>
              </div>
            </div>
          </li>
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Vegetables/shutterstock-676714621.png" alt="Sweet Potato"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Sweet Potato" href="#">Sweet Potato</a></h4>
              </div>
            </div>
          </li>                              
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Search;
