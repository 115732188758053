import React from "react";
const Export = (props) => {
   
  return (
    <React.Fragment>
<section className="product-details mt-50">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="product-details-content text-center mobile-center">
          <h1>Indian Coffee Exporters</h1>
          <p className="mt-50 text-justify ">Coffee is one of the most popular beverages across the world. It is brewed from roasted coffee beans. These beans are seeds of the berries from the Coffea species. These seeds are picked, processed, and then dried. Coffee is known to be a great energy booster. Coffee production in India is dominated by the southern states. Coffee exporters in India make good profits due to the rising demand worldwide. You can now export coffee from India and become an international coffee exporter!</p>
        </div>
        <div className="goal-wrapper mb-30 mobile-center prl-30">
          <h3></h3>
          <p className="text-justify "></p>
        </div>
      </div>
    </div>
  </div>
</section>
    </React.Fragment>
  );
};

export default Export;
