import React from "react";
import { Link } from "react-router-dom";

const Products = (props) => {
   
  return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{'list-style-type':'none !important'}}>         
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/dry-fruits/cashew.jpg" alt="Cashew"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="Cashew" to="/dryfruit-almonds">Cashew</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/walnut.jpg" alt="Walnut"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="Walnut" to="/dryfruit-almonds">Walnut</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/almonds.jpg" alt="almonds"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="almonds" to="/dryfruit-almonds">almonds</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/apricot.jpg" alt="apricot"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="apricot" to="/dryfruit-almonds">apricot</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/betel-nut.jpg" alt="betel-nut"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="betel-nut" to="/dryfruit-almonds">betel-nut</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/dates.jpg" alt="dates"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="dates" to="/dryfruit-almonds">dates</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/dry-fig.jpg" alt="dry-fig"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="dry-fig" to="/dryfruit-almonds">dry-fig</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/hazelnut.jpg" alt="hazelnut"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="hazelnut" to="/dryfruit-almonds">hazelnut</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/pista.jpg" alt="Pistachio"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="Pistachio" to="/dryfruit-almonds">Pistachio</Link></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <Link to="/dryfruit-almonds">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/product/dry-fruits/raisens.jpg" alt="raisens"/>
                </div>
              </Link>
              <div class="product-fruit-content text-center">
                <h4><Link class="name" title="raisens" to="/dryfruit-almonds">raisens</Link></h4>
              </div>
            </div>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Products;
