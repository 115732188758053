import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
       <section className="dehydrated-area pt-20 mrl-70">
       <div class="section-title-dehydrated text-center mb-50">
          <h2>Dehydrated Vegetable Varieties We Export</h2>
          <div class="dehydrated-img">
            <p class="text-justify pt-30 mb-30">The dehydrated vegetables exporters in India export dehydrated vegetables to different corners of the world. The export of dehydrated vegetables usually includes the export of dehydrated onion, dehydrated garlic, dehydrated cabbage, dehydrated beetroot so on and so forth.</p>
          </div>
        </div>

         <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Onion</h3>
          <div class="paragraph">
            <p class="text-justify ">Dehydrated Onions are onions from which moisture has been removed through a special drying process. The export of dehydrated onion from India has been on a rise for a few years now. Here are some of the varieties of Dehydrated onions exported from India:</p>
          </div>
        </div>
       
        <ul class="ul-type">
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Onion Flakes</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-onion-flakes.png" alt="dehydrated-onion-flakes" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Onion Flakes are usually used in canned soups, salads, hamburgers, sauce, pizzas and such other fast food preparations. They can also be easily used in salads & desserts. Dehydrated Onion Flakes contain 10-25 mm white onion flakes and are packed in a 12/14 Kg double layer poly bag in a paper bag or carton box.</p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Onion Chopped</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-onion-chopped.png" alt="dehydrated-onion-chopped" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Onion Chopped is used while preparing pizza, ready to eat foods, pickles, soups, and various food pest preparations. It can also be used in dry casserole mixes, stuffing mixes, food service and other fast foods. The Dehydrated Onion Chopped is around 3mm-5mm in size. The Dehydrated Onion Chopped is usually packed in a 20 Kg poly bag in a double-layer carton box.</p>
              </div>
          </div>
          
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Onion Granules</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-onion-granules.png" alt="dehydrated-onion-granules" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Onion Granules are widely in sauces, canned, soups, dry and frozen foods, salad dressings, meat products and such other food products wherever onion flavour & taste is desired. It is ideal for repacking for stores. These granules are about 0.5mm-1 mm in size and are packaged in a 20 Kg poly bag in a double-layer carton box.
                  </p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Onion Minced</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-onion-minced.png" alt="dehydrated-onion-minced" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Onion Minced is usually used while preparing sauces, canned soups, dry & frozen food, salad dressings, meat products, and other food products wherever onion flavour and the taste is desired. It is said to be ideal for repacking for stores. The dehydrated Onion Minced is around 1mm-3mm in size and is packed in a 20 Kg poly bag in a double-layer carton box.</p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li> Dehydrated Onion Powder</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-onion-powder.png" alt="dehydrated-onion-powder" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Onion Powder is generally used in sauces, canned soups, dry and frozen food, salad dressings, meat products, and in such other food products wherever onion flavour & taste is desired. It is ideal for repacking for stores. The Dehydrated Onion Powder is packed in a 20 Kg poly bag in a double-layer carton box.</p>
              </div>
          </div>
        </ul>

        <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Garlic</h3>
          <div class="paragraph">
            <p class="text-justify ">Dehydrated Garlic is the garlic from which moisture content has been removed. This is done through special drying processes. The export of dehydrated garlic has been increasing over the years due to the various diverse uses of the garlic. Here are some types of dehydrated garlic exported from India:</p>
          </div>
        </div>
        <ul class="ul-type">
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Garlic Flakes</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-garlic-flakes.png" alt="dehydrated-garlic-flakes" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Garlic flakes are known for their pungent flavour and are widely used in various culinary dishes. These flakes are used to season dry soups mixes, sauces, vegetable mixes, stuffing mixes, and fast foods. Around 7mm-15mm in size, these flakes are packed in a 25 Kg Double layer poly bag in an aluminium foiled bag. </p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Garlic Chopped</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-garlic-chopped.png" alt="dehydrated-garlic-chopped" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Garlic Chopped is widely used on pizzas and other fast food, snack foods, food service packs, stuffing mixes, pickled products, meat products, seafood products, gravies, canned foods, salad dressing, dips and various such dishes. These flakes are around 3mm-5mm in size and are usually packed in a 20 Kg double layer poly bag in a carton box.</p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Garlic Granules</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-garlic-granules.png" alt="dehydrated-garlic-granules" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">The Dehydrated Garlic Granules are mostly used in dry soups mixes, sauces, food preparations, vegetable mixes, stuffing mixes, fast foods, wet and dry salsa, specialty foods, curry powder, bakery topping, gourmet sauces, seasoning and so on and so forth. These granules are around 0.5mm-1mm in size and are packed in a 20 Kg double layer poly bag in a carton box.
                  </p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Garlic Minced</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-garlic-minced.png" alt="dehydrated-garlic-minced" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">The Dehydrated Garlic Minced is used in pizzas, snack foods, food service packs, pickled products, meat products, seafood products, canned foods, dips, bottle packs, pet food, rice mixes, wet and dry salsa, curry powder, seasoning and in various other snacks. Dehydrated garlic minced is around 1mm-3mm in size and packaged in a 20 Kg double layer poly bag in a carton box.</p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li> Dehydrated Garlic Powder</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-garlic-powder.png" alt="dehydrated-garlic-powder" class="pull-left mr-4" />
                  <p class="text-justify pt-20">Dehydrated Garlic Powder is widely used in sauces, gravies, meat products, soups, cheese, snacks, ethnic, butter spread, food service packs, stuffing mixes, pickled products, seafood products, canned foods, salad dressing, dips, bottle packs, pet food, rice mixes and so on. Dehydrated Garlic Powder is packed in a 20 Kg double layer poly bag in a carton box.</p>
              </div>
          </div>
        </ul>
        <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Tomato</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-tomato.png" alt="dehydrated-tomato" class="pull-left mr-4"/>
            <p class="text-justify pt-20">The demand for Dehydrated tomatoes has been on a rise over the years. This is because of the various uses of these tomatoes in different fields. Dehydrated tomatoes are used as a pizza topping, in sauteed veggies for extra flavour, in baked goods, in green salads, and various such food pest preparations. Due to the great variety of dehydrated tomatoes exported from India each year, the export of dehydrated tomatoes has been increasing rapidly.</p>
          </div>
        </div>
        <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Carrot</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-carrot.png" alt="dehydrated-carrot" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Dehydrated carrots are also growing in popularity for some years. Dehydrated carrots are used in various dishes including casseroles, loaves, pizza toppings, omelettes and bread, stews and soups. They also work well for stir-frying with other vegetables. Due to such diverse uses of dehydrated carrots, the export of dehydrated carrot from India has been increasing over the years.</p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40">
          <h3> Dehydrated Cabbage</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-cabbage.png" alt="dehydrated-cabbage" class="pull-left mr-4"/>
            <p class="text-justify pt-20">The export of dehydrated cabbage has been on a rise over the years. One may wonder why is it so? It is largely because of the various uses of Dehydrated cabbage in different preparations around the world. Dehydrated Cabbage is used in Soups and Slaws, casseroles and also added to soups or stews. It can also be easily used in salads and such other preparations.</p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40 pt-20">
          <h3> Dehydrated Beetroot</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-beetroot.png" alt="dehydrated-beetroot" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Beetroots are very healthy and are a rich source of natural sugar, sodium, potassium, phosphorus, chlorine, iodine, iron and various other important vitamins. The export of dehydrated beetroot has been increasing over the years. This is because of its numerous uses and benefits. Beetroots can be easily used in salads and such other preparations. They can also be eaten as crunchy, delicious, and versatile chips. They also have medicinal uses. </p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Basil Leaves</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-basil-leaves.png" alt="dehydrated-basil-leaves" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Dehydrated Basil Leaves are also being demanded across the world. This growing popularity of dehydrated basil leaves is because of its various health and medicinal properties. These leaves are used to add flavour to Tea. They are also used to recover from problems like dysentery, nausea and stomach ache due to gas. Due to its various uses, the export of dehydrated basil leaves has been on a rise.
            </p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Potato</h3>
              <div class="paragraph">
            <p class="text-justify">Dehydrated Potato is potato from which moisture content has been removed. Dehydrated potatoes are used because they have a longer shelf life and can be preserved well. The dehydrated potato exporters export various varieties of these dehydrated potatoes abroad. The dehydrated potato export from India mainly includes the export of Dehydrated Potato Flakes and the export of dehydrated potato powder. </p>
          </div>
        </div>
        
         
        <ul class="ul-type">
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Potato Flakes</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-potato-flakes.png" alt="dehydrated-potato-flakes" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Potato flakes are made from all-natural dehydrated potatoes. The potatoes are cooked, mashed and dehydrated to yield a packaged convenience food. Dehydrated Potato flakes can be reconstituted by adding hot water or milk. You can thus enjoy mashed potatoes in just a few minutes.</p>
              </div>
          </div>
          <div class="section-dveg mb-40">
            <h4><li>Dehydrated Potato Powder</li></h4>
              <div class="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-potato-powder.png" alt="dehydrated-potato-powder" class="pull-left mr-4"/>
                  <p class="text-justify pt-20">Dehydrated Potato Powder is very easy to use and store. Dehydrated Potato Powder is basically air Dried Potato Powder that is added to bread, casseroles, and vegetable side dishes. Potatoes are dehydrated under a constant stream of warm air, until the moisture content is less to manufacture dehydrated potato powder.</p>
              </div>
          </div>
        </ul>
        <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Bitter Gourd</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-bitter-gourd.png" alt="dehydrated-bitter-gourd" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Dehydrated bitter gourd has various uses and benefits. The dehydrated bitter gourds in the form of cubes or rings are used while preparing various curry while the brown bitter gourd is used for making bitter gourd tea. The main purpose of dehydration of bitter gourd is to extend its shelf life and reduce bulk storage, transportation and packaging. The dehydrated bitter gourd export from India has been on a rise since the past few years due to the convenience of the product.
            </p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40">
          <h3>Dehydrated Spinach Powder</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-spinach.png" alt="dehydrated-spinach" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Dehydrated spinach powder export from India has been increasing over the years. This is because this powder is rich in nutrition and is also beautifully mild. It can be used to make delicious spinach flavoured bread, pasta, sauces, Rotis & idlis so on and so forth. The powder should be stored in a cool and dry place and using a wet spoon to handle the powder should be avoided.
            </p>
          </div>
        </div>
         <div class="section-title-dehydrated mb-40 pt-20">
          <h3>Dehydrated Mushroom</h3>
              <div class="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-products/dehydrated-mushroom.png" alt="dehydrated-mushroom" class="pull-left mr-4"/>
            <p class="text-justify pt-20">Mushrooms are used in various cuisines across the world. Dehydrated mushrooms are now being used due to the long shelf life and the concentrated flavour. These mushrooms are ideal for adding depth to broths, stews, soups and such other dishes. Morels of mushrooms are also used at times. The dehydrated mushroom export from India has been increasing over the years due to the growing popularity of these mushrooms globally.
            </p>
          </div>
        </div>
         
        
         

        

   </section>

        </React.Fragment>
    );
};

export default Beverage;
