import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           <section className="description-product-area ptb-40">
    <div className="container">
        <span className="anchor" id="Ingredients"></span>
        <div className="goal-wrapper mb-30 ptb-20 ">
          <h3 className="center">Ingredients</h3>
          <table className="table table-hover table-responsive table-bordered">
            <tbody>
            <tr>
              <th scope="row">Nutrients</th>
              <td>Calories - 161 calories Dietary Fiber - 3.5 g Carbohydrate 2.5 g</td>
            </tr>
            <tr>
              <th scope="row">Protein</th>
              <td>6g</td>
            </tr>
            <tr>
              <th scope="row">Vitamins</th>
              <td>Vitamin E Vitamin B</td>
            </tr>
            <tr>
              <th scope="row">Minerals</th>
              <td>Calcium Selenium Zinc Magnesium Folate Biotin</td>
            </tr>
            <tr>
              <th scope="row">Fat</th>
              <td></td>
            </tr>
             
             {/* 
            
           
            
             
            
             
             */}
            
            
          </tbody>
        </table>
      </div>
    </div>
  </section>
        </React.Fragment>
    );
};

export default Beverage;
