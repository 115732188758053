import React from "react";
import Banner from "./banner";
import Search from "../../components/Search";
import Export from "./export";
import ProductList from "./productlist"
const Index = (props) => {
    return (
        <React.Fragment>
            <Banner />
            <Search />
            <div id="product-list">
                <Export />
                <ProductList/>
            </div>
        </React.Fragment>
    );
};

export default Index;
