import React from "react";
const Varities = (props) => {
    return (
        <React.Fragment>
            <section class="product-basmati pb-40">
                <div class="product-details-content text-center mobile-center">
                    <h2 class="pt-40">Almond Nuts Varieties We Export</h2>
                    <p class="mt-50 text-justify prl-60">The Almond Nuts import from India includes various varieties of Almond Nuts including Nonpareil, Carmel, Butte so on, and so forth. The Almond Nuts exporter in India exports premium quality products keeping health and safety regulations in mind. Here are the major varieties of Almond Nuts included in the export of Almond Nuts.</p>
                </div>
                <div class="container">
                    <div class="variety-holder prl-40">

                        <div class="single-fruits-choose landscape-single-fruit">
                            <div class="fruits-choose-serial variety-logo">
                                <h3  style={{color:'lightgreen',fontStyle:'normal'}}>1</h3>
                            </div>
                            <div class="goal-wrapper mb-30 mobile-center variety-content">
                                <h3>Nonpareil</h3>
                                <p class="text-justify">The Nonpareil variety of almonds has an attractive appearance and a strong almond profile. This variety is characterized by a thin outer shell and smooth nuts and blemish-free processing. It is a medium flat nut with light colour and a smooth surface.</p>
                            </div>
                        </div>

                        <div class="single-fruits-choose landscape-single-fruit">
                            <div class="fruits-choose-serial variety-logo">
                                <h3  style={{color:'lightgreen',fontStyle:'normal'}}>2</h3>
                            </div>
                            <div class="goal-wrapper mb-30 mobile-center variety-content">
                                <h3>Carmel</h3>
                                <p class="text-justify">The Caramel variety of Almond is technically a California type. But this variety is popular enough to be classified separately. These almonds are a softshell variety used in blanching and roasting. This variety is often substituted for nonpareils.</p>
                            </div>
                        </div>

                        <div class="single-fruits-choose landscape-single-fruit">
                            <div class="fruits-choose-serial variety-logo">
                                <h3  style={{color:'lightgreen',fontStyle:'normal'}}>3</h3>
                            </div>
                            <div class="goal-wrapper mb-30 mobile-center variety-content">
                                <h3 >Butte</h3>
                                <p class="text-justify">Butte is a Mission type of almonds. The shell of this variety is semi-hard with a light colour and smooth surface. The nut of this variety is small, short, plump with a wrinkled surface. This variety is suitable for export from India.</p>
                            </div>
                        </div>

                        <div class="single-fruits-choose landscape-single-fruit">
                            <div class="fruits-choose-serial variety-logo">
                                <h3  style={{color:'lightgreen',fontStyle:'normal'}}>4</h3>
                            </div>
                            <div class="goal-wrapper mb-30 mobile-center variety-content">
                                <h3 >Peerless</h3>
                                <p class="text-justify">Peerless is a California type of almond. The shell of this variety is hard with a light colour and smooth surface. The nuts are medium size with a wide shape and a fairly wrinkled surface.</p>
                            </div>
                        </div>

                        <div class="single-fruits-choose landscape-single-fruit">
                            <div class="fruits-choose-serial variety-logo">
                                <h3  style={{color:'lightgreen',fontStyle:'normal'}}>5</h3>
                            </div>
                            <div class="goal-wrapper mb-30 mobile-center variety-content">
                                <h3>Sonora</h3>
                                <p class="text-justify">The Sonora variety is a California type of almonds with a paper-thin shell, dark brown colour and rough surface. These nuts are large in size, long, narrow with a light colour and smooth surface.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Varities;
