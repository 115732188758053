import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>
  <div class="container-fluid">
    <div class="section-title-furits bg-shape text-center mb-80">
      <img class="lazyload" data-src="img/icon-img/leaf (1).png" alt="leaf-heading"/>
      <h2>Popular Products</h2>
    </div>
    <div class="product-fruit-slider owl-carousel owl-theme">
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/beverages/coffe.png" alt="Coffee"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Coffee" href="#">Coffee</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/beverages/tea.png" alt="Tea"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Tea" href="#">Tea</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/grains-and-cereal/white-rice.png" alt="Rice"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Rice" href="#">Rice</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/Fruits/coconut11597249835.png" alt="Coconut"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Coconut" href="#">Coconut</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/Spices/cumin-seeds-500x500.png" alt="Cumin"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Cumin" href="#">Cumin</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/Spices/turmeric-dosage-v2.png" alt="Turmeric"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Turmeric" href="#">Turmeric</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/Vegetables/potato.png" alt="Potato"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Potato" href="#">Potato</a></h4>
        </div>
      </div>
      
      <div class="product-fruit-wrapper item">
        <div class="product-fruit-img">
          <a href="#">
          <img class="lazyload" data-src="img/Vegetables/Red-Onion2-667x400.png" alt="Onion"/>
        </a>
        </div>
        <div class="product-fruit-content text-center">
          <h4><a title="Onion" href="#">Onion</a></h4>
        </div>
      </div>
      
    </div>
  </div>

      </React.Fragment>
    )
  }
}

export default Slider
