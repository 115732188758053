import React from "react";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <React.Fragment>
         <section className="banner-blog mt-140">
  <div className="container">
    <div className="breadcrumb-content">
      <h2>blogs</h2>
      <ul>
        <li><Link to="/" title="home">home</Link></li>
        <li> blogs</li>
      </ul>
    </div>
  </div>
</section>
    </React.Fragment>
  );
};

export default Banner;
