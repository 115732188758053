import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>

  <div class="container">
    <div class="row choose-row">
      <div class="">
        <div class="fruits-choose-wrapper-all">
          <div class="fruits-choose-title">
            <h2>WHY CHOOSE US ?</h2>
          </div>
          <div class="row mr50">
            <div class="col-sm-6">
              <div class="fruits-choose-wrapper">
                <div class="single-fruits-choose landscape-single-fruit">
                  <div class="fruits-choose-serial">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>1</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Get Buyers for Your Food Products</h4>
                    <p>No matter the type of food or spice you plan on exporting, we will ensure that you get genuine buyers for your products who meet your expectations.</p>
                  </div>
                </div>
                <div class="single-fruits-choose">
                  <div class="fruits-choose-serial">
                  <h3 style={{color:'lightgreen',fontStyle:'normal'}}>2</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Best quality food trading services</h4>
                    <p>Our stringent policies make sure that food handling and trading is in accordance with international quality standards.</p>
                  </div>
                </div>
                <div class="single-fruits-choose">
                  <div class="fruits-choose-serial">
                  <h3 style={{color:'lightgreen',fontStyle:'normal'}}>3</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Global Presence</h4>
                    <p>Even if you are planning to export across multiple continents, we’ve got you covered. Our global presence will allow your products to be exported to various countries.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="fruits-choose-wrapper">
                <div class="single-fruits-choose landscape-single-fruit">
                  <div class="fruits-choose-serial">
                  <h3 style={{color:'lightgreen',fontStyle:'normal'}}>4</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Market Analysis & Up-to-date Market Information</h4>
                    <p>Our thorough and regular analysis and research let us update our database with up to date information on the market and allows us to stay on top of every update.</p>
                  </div>
                </div>
                <div class="single-fruits-choose">
                  <div class="fruits-choose-serial">
                  <h3 style={{color:'lightgreen',fontStyle:'normal'}}>5</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Reliable & Trusted Services</h4>
                    <p>Trust is a pillar of our services. Our transparent and reliable operations ensure that you feel at ease with our services.</p>
                  </div>
                </div>
                <div class="single-fruits-choose">
                  <div class="fruits-choose-serial">
                  <h3 style={{color:'lightgreen',fontStyle:'normal'}}>6</h3>
                  </div>
                  <div class="fruits-choose-content">
                    <h4>Save your time & cost for searching markets</h4>
                    <p>Understanding and researching the market by yourself can be expensive and time-consuming. But with Pisum, we will do that for you instead.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

 


      </React.Fragment>
    )
  }
}

export default Slider
