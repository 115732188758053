import React from "react";
const Banner = (props) => {
  return (
    <React.Fragment>
       <div class="breadcrumb-area-listing beverages mt-140">
  <div class="container">
    <div class="breadcrumb-content">
      <h2>Beverages</h2>
      <ul>
        <li><a title="Home" href="index.html">home</a></li>
        <li> Beverages </li>
      </ul>
    </div>
  </div>
</div>

    </React.Fragment>
  );
};

export default Banner;
