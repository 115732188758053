import React from "react";
const Export = (props) => {
   
  return (
    <React.Fragment>
<section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Pulses Exporters</h2>
    </div>
      <p class="text-dark text-justify"> India today is a major exporter of various food and agricultural products. Among these, we have seen exponential growth in the pulses export from India in the past few years. The export of Pulses from India was around 2.70 lakh tonnes which are worth Rs. 1679.98 crores in the year 2018-2019. Pisum Foods is one of the most recommended Pulses importers from India. We import various varieties of Pulses including chickpeas, peas, kidney beans, soybeans so on, and so forth.</p>
      <p class="text-dark text-justify" id="space"> Being one of the largest Pulses exporters in India, Pisum foods makes sure that the products reach the destinations fresh and on the promised time. We have connections across the globe and provide hassle-free export of Indian pulses. We are a pulses exporter who believes in transparency of services and is always happy to help with any issues our clients may have. We wish to spread Pulses in India and other Indian products to every corner of the world through our services. If you want to Pulses import from India, Pisum is the place for you!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Export;
