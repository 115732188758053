import React from "react";
import Banner from "./banner"
import Export from "./export"
import ProductList from "./productlist"

const Search = (props) => {

    return (
        <React.Fragment>
            <Banner />
            <Export />
            <ProductList />
        </React.Fragment>
    );
};

export default Search;
