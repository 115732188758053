// import React, { Component } from "react"
// // Layout Related Components
// import Header from "./Header"
// import Footer from "./Footer"

// class Layout extends Component {

//   render() {
//     console.log("In Child componet",this.props.children)

//     return (
//       <React.Fragment>
//         <div id="layout-wrapper">
//           <Header  />
         
//           {this.props.children}
//           <Footer />
//         </div>
//       </React.Fragment>
//     )
//   }
// }

// export default Layout;
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"
import { appendScript } from "../../pages/Util/script";

class Layout extends Component {
  componentDidUpdate() {
    appendScript("https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js");
    appendScript("js/popper.js");
    appendScript("js/bootstrap.min.js");
    appendScript("js/lazysizes.min.js");
    appendScript("js/owl.carousel.min.js");
    appendScript("js/plugins.js");
    appendScript("https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.7.0/js/lightgallery-all.min.js");
    appendScript("https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.1/jquery.validate.min.js");
    appendScript("https://cdnjs.cloudflare.com/ajax/libs/list.js/1.5.0/list.min.js");
    // appendScript("js/validator.js");
    appendScript("js/main.js");
    appendScript("js/search-product.js");
    appendScript("js/formspree-api-contact.js");
    appendScript("js/formspree-api-supplier.js");
    appendScript("https://kwesforms.com/js/v2/kwes2aa5.js");

  }
  render() {
    return (
      <React.Fragment>

        <div id="layout-wrapper">
          <Header  />
         
          <div>{this.props.children}</div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default (withRouter(Layout))

