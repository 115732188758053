
    import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           
  
    <section className="product-basmati pb-40">
    <div className="product-details-content text-center mobile-center">
      <h2 className="pt-40">Tea Varieties We Export</h2>
      <p className="mt-50 text-justify prl-60">India exports many varieties of tea including Ginger Tea, Cardamom Tea, Kashmiri Tea, Butter Tea, Green Tea, and so on. India exports tea to various countries in the European Union and the states. You can now export tea from India. With the beautiful flavours, aromas and textures of the varieties on tea, they also have several medicinal and healthy benefits. Tea is very good for the heart and is rich in antioxidants. Some varieties of tea are also recommended for weight loss and strengthen your bones. Drinking tea also boosts your immune system and metabolism. You should definitely try these varieties of tea!</p>
    </div>
    <div className="container">
      <div className="variety-holder prl-40">
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div class="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>1</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Darjeeling</h3>
            <p className="text-justify">Tea is one of the most popular beverages in India. Darjeeling tea is famous across the globe. It is known for its powerful antioxidant qualities. It's also known to keep your heart health happy. This tea is grown in Darjeeling as the name states. It cannot be grown anywhere else in the world. Original Darjeeling Tea possesses a flavour and quality, which sets it apart from other teas.</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div class="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>2</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Assam</h3>
            <p className="text-justify">It is said, ‘you haven’t woken up fully if you haven’t sipped Assam tea’. Assam Tea is generally deep-amber colour. Known for its strong and malty character, this tea is very popular in India and across the world. The unique taste and aroma of this tea have been crafted by the region’s rich loamy soil, unique climate and rainfall.</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
         
          <div class="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>3</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Nilgiri</h3>
            <p className="text-justify">This tea originates from the beautiful Nilgiri Hills in the Tamil Nadu region of India. Nilgiri tea is known for its delicious fragrance and exquisite aroma. This tea is crisply brisk and bright in colour. With its creamy mouthfeel and unique flavour, this tea is perfect to get rid of stress and worries!</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div class="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>4</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Kangra</h3>
            <p className="text-justify">Found in the Kangra region of Himachal, the Kangra tea is famous for its quality, unique aroma and the tinge of fruity flavour. In terms of flavour, it is a little mild compared to Darjeeling tea but has more body and liquor. The popularity of Kangra tea has been steadily increasing over the years.</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div class="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>5</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Munnar</h3>
            <p className="text-justify">The Hills of Munnar is famous for nature and tea. Munnar tea is known to have a medium-toned fragrance of sweet biscuit in a dip malt.  It is a tea full of surprises and unexpected flavours and tastes! After you finish sipping Munnar tea, a startling note of sweetness will linger.</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>6</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Dooars-Terai</h3>
            <p className="text-justify">The area in the district of Jalpaiguri, West Bengal, along with a small part of Coochbehar District, is known as Dooars which mean Doors. The Dooars-Terai tea is popular from this region. This tea is characterized by a bright, smooth and full-bodied liquor that’s and is a bit lighter than Assam tea.</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          {/* <div className="fruits-choose-serial ">
            <h3>7</h3>
          </div> */}
          <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>7</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Masala Tea</h3>
            <p className="text-justify">Masala Chai is the most loved tea in India. Its the perfect blend of Spicy, strong, malty, earthy with a tinge of cardamom, ginger, cinnamon, and so on. The Indian Black Tea makes this tea taste the best. Masala chai is ideal to drink in the morning or evening, with snacks or without, this tea will definitely win your heart!</p>
          </div>
        </div>
        
        <div className="single-fruits-choose landscape-single-fruit">
          
          <div className="fruits-choose-serial variety-logo">
                    <h3 style={{color:'lightgreen',fontStyle:'normal'}}>8</h3>
                  </div>
          <div className="goal-wrapper mb-30 mobile-center variety-content">
            <h3>Sikkim Tea</h3>
            <p className="text-justify">Sikkim is famous for its tea garden across the world. The tea leaves are plucked delicately and are used to prepare a blend that is light, flowery, golden yellow and of a delicate flavour. The first flush of Sikkim Tea has a unique taste and aroma while the second flush is mellow and smooth with a toasty brew. The third flush of Sikkim Tea has a mellow taste while the final flush has a well-rounded taste with a hint of spices.</p>
          </div>
        </div>
        
      </div>
      </div>
    </section>
        </React.Fragment>
    );
};

export default Beverage;

