
import React from "react";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <React.Fragment>
       <section class="our-branches p97">
  <div class="container">
    <div class="section-title-furits text-center mb-20">
      <img
        class="lazyload"
        data-src="/img/icon-img/49.png"
        alt="Leaf-heading"
      />
      <h2 id="branches">Our Branches</h2>
    </div>
    <div class="row">
      
      <div class="col-md-4 col-sm-6 single-branch">
        <div class="contact-info-wrapper">
          <div class="contact-title">
            <h4>Pune</h4>
          </div>
          <div class="contact-info">
            <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="contact-info-text">
                <p>&nbsp; MUYONI TRADING LLP.
Planet Millennium | No. 502, | Bldg. A/15
S. No. 74 | Pimple Saudagar
Pune-411027 Maharashtra State, India.</p>
              </div>
            </div>
            {/* <!-- <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="pe-7s-mail"></i>
              </div>
              <div class="contact-info-text">
                <p><span>Email: </span> contact@pisumfoods.com </p>
              </div>
            </div>
            <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="pe-7s-call"></i>
              </div>
              <div class="contact-info-text">
                <p><span>Phone: </span>  (800) 0123 456 789</p>
              </div>
            </div> --> */}
          </div>
        </div>
      </div>
      
      <div class="col-md-4 col-sm-6 single-branch">
        {/* <div class="contact-info-wrapper">
          <div class="contact-title">
            <h4>Mumbai</h4>
          </div>
          <div class="contact-info">
            <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="contact-info-text">
                <p>&nbsp; 282, Khandke Building, Office No. 21, 2nd floor, Shahid Bhagat Singh Road, Fort Market, Near Sher-e-Punjab Hotel, Mumbai- 400 001</p>
              </div>
            </div>
            
          </div>
        </div> */}
      </div>
      
      <div class="col-md-4 col-sm-6 single-branch">
        <div class="contact-info-wrapper">
          <div class="contact-title">
            <h4>Solapur</h4>
          </div>
          <div class="contact-info">
            <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="contact-info-text">
                <p>&nbsp; Bhawani peth maddi wasti,near Datta Mandir,Solapur.</p>
              </div>
            </div>
             {/* <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="pe-7s-mail"></i>
              </div>
              <div class="contact-info-text">
                <p><span>Email: </span> contact@pisumfoods.com </p>
              </div>
            </div> */}
            {/* <div class="single-contact-info">
              <div class="contact-info-icon">
                <i class="pe-7s-call"></i>
              </div>
              <div class="contact-info-text">
                <p><span>Phone: </span>  (800) 0123 456 789</p>
              </div>
            </div>  */}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>
    </React.Fragment>
  );
};

export default Banner;
