import React from "react";
import  Banner from "./banner";
import Search from "../../components/Search";
import Export from "./export";
import Products from "./productlist"
const DryFruit = (props) => {
   
  return (
    <React.Fragment>
    <Banner/>
    <Search/>
    <Export/>
    <Products/>
    </React.Fragment>
  );
};

export default DryFruit;
