import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           <div className="breadcrumb-area-listing tea-banner mt-140">
  <div className="container">
  </div>
</div>

        </React.Fragment>
    );
};

export default Beverage;
