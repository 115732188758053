import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>


  <div class="section-title-furits text-center mb-40">
    <img class="lazyload" data-src="img/icon-img/leaf (1).png" alt="leaf-heading"/>
    <h2>Why the Indian market is Best?</h2>
  </div>
  <div class="fruits-services-wrapper">

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Agriculture Base</h4>
        <p>With over 50 per cent of India’s workforce involved in the agriculture sector, the country is one of the leading agriculture exporters.</p>
      </div>
    </div>

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Affordable Rates</h4>
        <p>Cheap labour and raw materials allow India to produce spices, oils, and other food products at great affordable rates.</p>
      </div>
    </div>

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Best Quality Food Products</h4>
        <p>Any food product manufactured in India goes through quality assurance tests and certifications to ensure that they are of high quality.</p>
      </div>
    </div>

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Variety of Spices and Tastes</h4>
        <p>Being known as the land of spices, the country is the leading producer of spices. The country boasts of a huge variety of spices providing a great range of flavour.</p>
      </div>
    </div>

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Diversity of India and Indian Culture</h4>
        <p>The diverse Indian cultures are the reason behind the diversified food products available. With each region of the country possessing its own blend and flavour, no country does this better.</p>
      </div>
    </div>

    <div class="single-fruits-services">
      <div class="fruits-services-content">
        <h4>Technology and Innovation</h4>
        <p>In a land blessed with great scientific minds, India’s technological advancements and innovation have made international trade all the easier, making it the perfect choice.</p>
      </div>
    </div>

  </div>
      </React.Fragment>
    )
  }
}

export default Slider
