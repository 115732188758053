import React from "react";
import Banner from "./banner";
import Enquire from "../components/enquire";
import Blog from "./blog"

const Blogs = (props) => {
  return (
    <React.Fragment>
        <Banner/>
        <Blog/>
		<Enquire/>
    
    </React.Fragment>
  );
};

export default Blogs;
