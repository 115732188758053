import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
            <section className="product-details  dpt-170 " style={{'background-color': '#e8f8df'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
        <div className="section-title-dehydrated text-center mb-80">
          <img className="lazyload" data-src="/img/icon-img/49.png" alt=""/>
          <h1>Dehydrated Fruits Exporters</h1>
        </div>
      </div>
    </div>
          <div className="col-md-12 dehydrated-img">
            <p className="text-justify pt-30 pb-40">Dehydration is the process of removal of moisture or water from fruits, vegetables and such other products. Dehydrated fruits are now demanded across the world. This is because of the diverse uses and benefits of these dehydrated fruits and vegetables. The dehydrated fruits in India are highly nutritious and easy to store. As the water content has been removed, these fruits also have a long shelf life. Thus, the import of dehydrated fruits from India has been on a rise over the last few years. </p>
          </div>
  </div>
    </section>
        </React.Fragment>
    );
};

export default Beverage;
