import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
           <div class="breadcrumb-area-listing banner-almond-nuts mt-140">
  <div class="container">
  </div>
</div>
        </React.Fragment>
    );
};

export default Beverage;
