import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
  <section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Fruits Exporters</h2>
    </div>
      <p class="text-dark text-justify"> India is popularly known as ‘the fruits basket of the world’. In India, we have been blessed with favorable climatic and soil conditions which help the growth of not only fruits in India, but also vegetables, grains, pulses, and such other crops. Most of the fruits are grown in India including mangoes, bananas, papayas, oranges, apricots, grapes, strawberries, apples, guavas, litchis, so on, and so forth. Therefore, fruits export from India has been on the rise for a few decades. The export of fruits from India was around 372213.73 Metric tonnes in the year 2018-2019.</p>
      <p class="text-dark text-justify" id="space"> There are numerous fruits importer from India including countries like Bangladesh, Nepal, Oman, Saudi Arabia, UAE, and many more. Pisum Foods us one of the leading fruits exporter in India. We conduct export of Indian fruits to various parts of the world. Being one of the most experienced fruits exporters, our motto is to spread the Indian food products and the Indian culture to every corner of the world. We at Pisum Foods make sure that your food products reach the buyer well packaged and on the promised time. If you are looking for fruits import from India, Pisum can help you at every step on the way!</p>
  </div>
</section>


    </React.Fragment>
  );
};

export default Search;
