import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>
    <div class="section-title-furits text-center mb-95">
      <img class="lazyload" data-src="img/icon-img/leaf (1).png" alt=""/>
      <h2> What Our Clients Say!</h2>
    </div>
    <div class="container">
      {/* <div class="testimonials-active owl-carousel"> */}
        <div class="fruits-single-testimonial text-center">
          <img alt="" class="lazyload" data-src="img/testimonial/CYDA_Logo.png"/>
          <h3>A Great Big Thank you</h3>
          <p>Let we take the opportunity to convey our "A Great Big Thank you" for
           swiftly supplying us with prompt and efficient delivery of the Kits at the time of crisis. </p>
           <p>We really admire the dedication and your teamwork in making us get our order in time.
            We shall highly recommend Your Esteemed organization to our prospects and will surely look forward to developing good relations.</p>
            <p>Thanks once again for the entire team to make this a 'Success'.</p>
          <div class="client-name">
            {/* <!--<span class="client-name-bright">Martha White  /</span>--> */}
            <span> Centre For Youth Development & Activities (CYDA), Pune</span>
          </div>
         
        <div id="lightgallery" class="row">

          <div  class="col-md-12 single-certificate lazyload"
                data-src="img/certificates/CYDA-appreciation-letter.jpg">

            <a href="javascript:void(0);" title="">
              <img
                class="mx-auto d-block"
                src="img/certificates/test.png"
                alt=""
                />
            </a>

          </div>
        {/* </div> */}
      </div>
    </div>
    </div>
 

      </React.Fragment>
    )
  }
}

export default Slider
