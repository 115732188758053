import React from "react";
import Banner from "./banner";
import Contact from "./contact"
import Branches from "./branches"
const Blogs = (props) => {
  return (
    <React.Fragment>
        <Banner/>
        <Contact/>
		<Branches/>    
    </React.Fragment>
  );
};

export default Blogs;
