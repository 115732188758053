import React from "react";
const Product = (props) => {
   
  return (
<React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{'list-style-type': 'none !important'}}>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Oilseeds/sunflower-seeds-500x500.png" alt="Sunflowers"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Sunflowers" href="#">Sunflowers</a></h4>
              </div>
            </div>
          </li>                              
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Oilseeds/whole-soybeans-500x500.png" alt="Soybeans"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Soybeans" href="#">Soybeans</a></h4>
              </div>
            </div>
          </li>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Oilseeds/sesame-seeds-wooden-spoon.png" alt="Sesame"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Sesame" href="#">Sesame</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Oilseeds/flax-seeds-500x500.png" alt="Flax"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Flax" href="#">Flax</a></h4>
              </div>
            </div>
          </li>
          
          
          
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Oilseeds/safflower-seed_8eba3073-4749-4c6c-a6af-72d2cf9217a1_360x.png" alt="Safflower"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Safflower" href="#">Safflower</a></h4>
              </div>
            </div>
          </li>
        
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="/img/Oilseeds/Meadowfoam-seed-oil.png" alt="Meadowfoam"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Meadowfoam" href="#">Meadowfoam</a></h4>
              </div>
            </div>
          </li>
         
         
         </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default Product;
