import React from "react";
const ProductList = (props) => {
   
return (
    <React.Fragment>
<div class="wrapper mrl-80">
    <div class="sub-listing-area pt-40 pb-90">
      <div class="container">
        <ul class="list row" style={{"list-style-type": "none"}}>
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Floriculture/tea-rose-garden-roses-plants-stem-flowers.png" alt="Rose"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Rose" href="#">Rose</a></h4>
              </div>
            </div>
          </li>
                              
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Floriculture/gerbera-daisies-gerbera-jamesonii-come-in-a-vari.png" alt="Gerbera"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Gerbera" href="#">Gerbera</a></h4>
              </div>
            </div>
          </li>
                                                  
          <li class="col-xl-3 col-md-4">
            <div class="product-fruit-wrapper mb-30">
              <a href="#">
                <div class="product-fruit-img">
                  <img class="lazyload" data-src="img/Floriculture/51yaocPD7pL.png" alt="Crossandra"/>
                </div>
              </a>
              <div class="product-fruit-content text-center">
                <h4><a class="name" title="Crossandra" href="#">Crossandra</a></h4>
              </div>
            </div>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
    </React.Fragment>
  );
};

export default ProductList;
