import React, { Component } from "react"

class Slider extends Component {
  
  render() {
    return (
      <React.Fragment>
        <section className="slider-area mb-100">
  <div className="owl-carousel owl-theme banner-slider">
      {/* <h2>Hello Sadiys</h2> */}
    <div className="owl-slide d-flex align-items-center cover" style={{backgroundImage: 'url(img/banner/slider-1.jpg)'}}>
      <div className="container">
        <div className="text-center text-white main-home-heading">
          <h1>Global Quality Food Exporter From India</h1>
          <h3 className="text-white">We export food including raw vegetables, fruits, spices & many more</h3>
        </div>
      </div>
    </div>

    <div className="owl-slide d-flex align-items-center cover" style={{backgroundImage: 'url(img/banner/slider-2.jpg)'}}>
      <div className="container">
        <div className="text-center text-white main-home-heading">
           <h1>Global Quality Food Exporter From India</h1>
          <h3 className="text-white">We export food including raw vegetables, fruits, spices & many more</h3> 
        </div>
      </div>
    </div>

    <div className="owl-slide d-flex align-items-center cover" style={{backgroundImage: 'url(img/banner/slider-3.jpg)'}}>
      <div className="container">
         <div className="text-center text-white main-home-heading">
          <h1>Global Quality Food Exporter From India</h1>
          <h3 className="text-white">We export food including raw vegetables, fruits, spices & many more</h3>
        </div> 
      </div>
    </div>
  </div>
</section>
      </React.Fragment>
    )
  }
}

export default Slider
