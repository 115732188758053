import React from "react";
const Beverage = (props) => {
    return (
        <React.Fragment>
      

    <section className="dehydrated-area pt-20 mrl-70">
        <div className="section-title-dehydrated text-center mb-50">
          <h2>Global Market Demand of Dehydrated Fruits</h2>
          <div className="dehydrated-img">
            <p className="text-justify pt-30 mb-30">The global market demand for dried fruits in India has been increasing because of the convenience of these fruits. These fruits are very portables and make healthy and delicious snacks on the go. It's always better to eat dehydrated fruits for snacks rather than fried, oils dishes which are harmful for your health. These fruits are rich in nutrients, minerals and antioxidants and provide instant energy in the middle of a tiring day. The import of dried fruits from India has therefore been increasing over the years. </p>
          </div>
        </div>

                <div className="section-title-dehydrated text-center mb-50">
          <h2> Dehydrated Fruits Varieties We Export</h2>
          <div className="dehydrated-img">
            <p className="text-justify pt-30 mb-30">The dehydrated fruits exporters export various varieties of these dried fruits from India. Some of the most widely exported dehydrated fruits include apples, pineapples, papayas, bananas, strawberries and many more. Here are the major fruits included in the export of dehydrated fruits from India: </p>
          </div>
        </div>

         <div className="section-title-dehydrated mb-40">
          <h3> Dehydrated Apples</h3>
          <div className="dehydrated-img">
            <p className="text-justify pt-20">Apples are sweet, crunchy and satisfying fruits. Being one of the most delicious fruits, apples are also full of nutrients. Dehydrated apples are also popular nowadays due to their diverse uses and benefits. The export of dehydrated apples is on the rise over the years. This is because of the growing global demand for these apples. The dehydrated apples export from India mainly contains the export of dehydrated apple flakes and dehydrated apple slices.</p>
          </div>
        </div>
        <ul className="ul-type">
          <div className="section-dveg mb-40">
            <h4><li> Dehydrated Apple Flakes</li></h4>
              <div className="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-apple-flakes.png" alt="dehydrated-apple-flakes" className="pull-left mr-4"/>
                  <p className="text-justify pt-20">Dehydrated Apple flakes are also demanded in various countries across the world. This is because of the rich flavour and aroma of apples. Even though the apples are dehydrated they have high nutritional values and long shelf life. Thus, dehydrated apple flakes are in demand today.</p>
              </div>
          </div>
          <div className="section-dveg mb-40">
            <h4><li>Dehydrated Apple Slices</li></h4>
              <div className="col-md-11 offset-md-1 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-apple-sliced.png" alt="dehydrated-apple-sliced" className="pull-left mr-4"/>
                  <p className="text-justify pt-20">Dehydrated Apple Slices are usually red or green in colour depending on which apples have been dehydrated. These slices are usually dried using food dehydrators. These fruits are largely consumed as they are rich in fibre and antioxidants and also have a long shelf life</p>
              </div>
          </div>

        </ul>

         <div className="section-title-dehydrated mb-40">
          <h3>Dehydrated Chikoo</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dried-chikoo.png" alt="dried-chikoo" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Sapota popularly known as Chikoo is a delicious tropical fruit. It is well-known for its light musky flavour and grainy texture. Nowadays, dehydrated chikoo is also famous in the market. This is because of its numerous health benefits. It is rich in vitamin A & C, helps in digestion, provides energy, good for the bones, controls the blood pressure and offers various such other benefits. The export of dehydrated chikoo has also seen exponential growth for a few years now. This increase in dehydrated chikoo export from India is also because of the longer shelf life of these chikoos.
           </p>
          </div>
        </div>

         <div className="section-title-dehydrated mb-40">
          <h3> Dehydrated Kiwi</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-kiwi.png" alt="dehydrated-kiwi" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Kiwi is a small-sized fruit also known as Chinese gooseberry in different parts of the world. Kiwi is widely recognized for its plenty of health benefits and delectable flavour. Kiwis are a nutritional powerhouse of dietary fibre, vitamins, minerals, carbohydrates, fats and proteins. Now the export of dehydrated kiwi is increasing from India. This is because of the high nutritional value and the longer shelf life of the products. The dehydrated kiwi export from India includes the export of dehydrated kiwi natural and dehydrated kiwi processed.</p>
          </div>
        </div>
         <div className="section-title-dehydrated mb-40">
          <h3>Dehydrated Mango</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-mango.png" alt="dehydrated-mango" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Mangoes are some of the most loved fruits worldwide. Now, the sweet, nutritious and fresh selection of dried mangoes is also popular globally. Thus the export of dehydrated mango has been on a rise for a few years now. The dehydrated mango export from India mainly includes the export of dehydrated mango natural and dehydrated mango processed. Dehydrated Mangoes are high in soluble and insoluble fibre and are an excellent source of both types of dietary fibre. The soluble fibre in dehydrated mangoes helps to stabilize blood sugar levels, increases nutrient absorption and may also reduce LDL “bad” cholesterol levels. The insoluble fibre in these mangoes promotes a healthy digestive system.</p>
          </div>
        </div>
         <div className="section-title-dehydrated mb-40">
          <h3>Dehydrated Orange</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-orange.png" alt="dehydrated-orange" className="pull-left mr-4"/>
            <p className="text-justify pt-20">The export of dehydrated orange from India has been on a rise over the years. This is because of the diverse uses and the storage benefits of dehydrated oranges. As the moisture content is removed, the dehydrated oranges have a longer shelf life as compared to regular oranges. The dehydrated oranges are also portable and make healthy and tasty snacks even during travel. These oranges are used for garnishing and can also be ground into a powder which is used to flavour soups, stews, so on and so forth. Thus, the dehydrated orange export from India has been increasing over the years.
            </p>
          </div>
        </div>
         <div className="section-title-dehydrated mb-40">
          <h3>Dehydrated Papaya</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-papaya.png" alt="dehydrated-papaya" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Papaya is one of the most healthy tropical fruits. It is loaded with antioxidants that help in reducing the inflammation and keeps you young. Papaya is not just known for its various health benefits but also for its vibrant colour and sweet taste. Dehydrated papaya is now widely demanded across the world because of its long shell life, exotic sweet taste and unique flavour. The export of dehydrated papaya has been on a rise over the years. The dehydrated papaya export from India largely consists of the export of dehydrated papaya flakes.
            </p>
          </div>
        </div>
         <div className="section-title-dehydrated mb-40">
          <h3> Dehydrated Pineapple</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-pineapple.png" alt="dehydrated-pineapple" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Dehydrated pineapple is known to be one of the magical fruits. It is loaded with natural sugars and vitamins that bring instant energy whenever you feel tired. Dehydrated pineapple is generally made using a vacuum dehydration process based on the latest technology mechanism. The export of dehydrated pineapple has been increasing over the period of time.This is because dehydrated fruits give the feel of candy and chewiness with a delicious taste. The dehydrated pineapple export from India has also been increasing rapidly because of its use in various dishes across the world.</p>
          </div>
        </div>

         <div className="section-title-dehydrated mb-40">
          <h3>Dehydrated Strawberry</h3>
              <div className="col-md-12 dehydrated-img">
                <img src="/img/dehydrated-fruits/dehydrated-strawberry.png" alt="dehydrated-strawberry" className="pull-left mr-4"/>
            <p className="text-justify pt-20">Dehydrated strawberries are the ones from which moisture content has been removed. Dried Strawberries are powerhouses of nutrition and are rich in fibre, potassium and such other nutrients and minerals. They make delicious and healthy snacks and being dehydrated are also easy to carry around. The export of dehydrated strawberries has been increasing from India. The dehydrated strawberry export from India includes the export of various forms of these dehydrated fruits including powder form, slices so on and so forth.
            </p>
          </div>
        </div>

   </section>

        </React.Fragment>
    );
};

export default Beverage;
