import React from "react";
import Banner from './banner';
import Export from "./export";
import UpFoot from "./footer"
const Beverage = (props) => {
    return (
        <React.Fragment>
           <Banner/>
           <Export/>
           <UpFoot/>
        </React.Fragment>
    );
};

export default Beverage;
