import React from "react";
const Export = (props) => {
   
  return (
    <React.Fragment>
 <section className="pb-105 mrl-80">
  <div className="container">
    <div className="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Floriculture Exporters</h2>
    </div>
      <p className="text-dark text-justify"> Floriculture in India primarily means the cultivation of flowers or flower farming. Floriculture export from India includes the export of flowers like roses, Gerbera, Tulips, Carnation, Orchid, Jasmine, Marigold, Aster so on, and so forth. Export of floriculture has to be done with the utmost care and in an organized manner as most of these flowers are delicate and have to be handled carefully. Pisum is a leading floriculture importer from India. We export various varieties of flowers to different regions in the world.</p>
      <p className="text-dark text-justify" id="space"> Pisum is known for its high-quality products and the health and safety regulations we strictly follow. Known to be one of the most popular floriculture exporters in India, we have been accredited to TPCI, APEDA, and such other certifications. We make sure that the export of Indian Floriculture is done perfectly from the documentation, finding buyers, until the products reach the destination. We at Pisum Foods understand that timely delivery of the products is an important factor for a floriculture exporter. We thus make sure that your delivery reaches in a timely manner. If you are looking for floriculture import from India, Pisum is here to serve you!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Export;
