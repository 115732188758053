import React from "react";
import Banner from "./banner";
import Enquire from "../components/enquire";
import Certificates from "./certificates"
const About = (props) => {
  return (
    <React.Fragment>
        <Banner/>
		<Certificates/>
		<Enquire/>
    </React.Fragment>
  );
};

export default About;
