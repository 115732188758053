import React from "react";
const Search = (props) => {
   
  return (
    <React.Fragment>
 <section class="pb-105 mrl-80">
  <div class="container">
    <div class="section-title-furits text-center mb-80">

      <h2 id="spice-head">Indian Vegetables Exporters</h2>
    </div>
      <p class="text-dark text-justify"> Fruits, vegetables, spices, grains, pulses, and such other crops thrive in India. Due to the large amounts of vegetables produced in India, we can vegetable export from India too. Many vegetables are included in the export of vegetables from India like Onion, Broccoli, Brinjal, Spinach, cabbage, Green chilies so on, and so forth. Pisum Foods is a leading vegetable importer from India today. We work on the export of Indian vegetables, fruits, spices, pulses, oilseeds, and such other products.</p>
      <p class="text-dark text-justify" id="space"> At Pisum Foods, we believe that the Customer is our King. We, therefore, assist our clients in each step on the way while exporting vegetables in India. Being one of the best vegetable exporters in India, we strictly follow all the health and safety measures of the Government of India. Pisum Foods is the largest and most reliable vegetable exporter in India. Our processes are completely transparent and we make sure that the desired products reach their destination safe, fresh, and on time. If you are looking for vegetable import from India, Pisum Foods is the best choice!</p>
  </div>
</section>
    </React.Fragment>
  );
};

export default Search;
