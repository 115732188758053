import React from "react";
import Banner from "./banner"
import Export from "./export";
import Varities from "./varities"
const Search = (props) => {
   
  return (
    <React.Fragment>
<Banner/>
<Export/>
<Varities/>
    </React.Fragment>
  );
};


export default Search;
