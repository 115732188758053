import React,{useEffect} from "react";
import Banner from './banner';
import Export from "./export";
import Varities from "./varities"
import { appendCss } from "../../../Util/externalcss";
const Beverage = (props) => {
    useEffect(()=>{
        // appendCss('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css')
        // appendCss('https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.7.0/css/lightgallery.css')
        // appendCss('../css/main.css')
        // appendCss('../css/responsive.css')       
    },[])
    return (
        <React.Fragment>
           <Banner/>
           <Export/>
           <Varities/>
        </React.Fragment>
    );
};

export default Beverage;
