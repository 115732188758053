import React from "react";

const Loader = (props) => {
  return (
    <React.Fragment>       
      <div id="ftco-loader" class="show fullscreen">
        <svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#85bd02"/></svg>
      </div> 
    </React.Fragment>
  );
};

export default Loader;
